import moment from "moment";
// import { getAssigneeId } from "../web/components/stateless/patient/readingsData/weight";
import { UserRoles } from "../web/constants/enums";
import {
  enumerateDaysBetweenDates,
  getBoundsForSingleReading,
  getFullChartDataWithEmptyData,
  getThresholdsAccToUser,
} from "./utils";

const getAppUser = () => JSON.parse(
  JSON.parse(localStorage.getItem("persist:@@appStateStores/STATE") || `""`)
    ?.Auth || `""`
)?.appUser;

export const getAssigneeId = (bound: any, user: any) => {
  if (user?.roleName == UserRoles.PROVIDER) {
    return user?.id;
  } else {
    return Object.keys(bound)[0];
  }
};
function formatDate(timestamp: any) {
  return moment.utc(timestamp).local().format("MMM DD");
}

function getFinalPlotData(data: any[], args: any) {
  return getFullChartDataWithEmptyData(
    data,
    enumerateDaysBetweenDates(
      moment(args?.startDate, "YYYY-MM-DD"),
      moment(args?.endDate, "YYYY-MM-DD")
    )
  );
}
export const getActiveBoundforWeight = (data: any) => {
  var x = "";
  const bound = data?.Bounds ? data?.Bounds : {};
  var id = getAssigneeId(bound, getAppUser());
  if (bound[id]?.some((th: any) => th?.boundType == "heartFailure")) {
    x = "heartFailure";
  } else if (bound[id]?.some((th: any) => th?.boundType == "obesity")) {
    x = "obesity";
  }
  return x;
};

export const getBPData = (data: any[], args: any) => {
  var x = data?.map(({ worstData }: any) => {
    let { Bounds } = worstData;    return {
      day: moment.utc(worstData?.date).local().format("MMM DD"),
      systolic: worstData?.systolic?.value,
      diastolic: worstData?.diastolic?.value,
      heartRate: worstData?.heartRate?.value,
      systolicBound: getBoundsForSingleReading(
        getThresholdsAccToUser(Bounds, getAppUser()),
        "systolic"
      ),
      diastolicBound: getBoundsForSingleReading(
        getThresholdsAccToUser(Bounds, getAppUser()),
        "diastolic"
      ),
      heartRateBound: getBoundsForSingleReading(
        getThresholdsAccToUser(Bounds, getAppUser()),
        "heartRate"
      ),
    };
  });
  var final = getFinalPlotData(x, args);
  return final;
};

export const getGlucoseChartData = (data: any, args: any) => {
  const result: any[] = [];
  let metaData = [
    ...(data?.beforeBreakfast || []),
    ...(data?.afterBreakfast || []),
    ...(data?.beforeDinner || []),
    ...(data?.unScheduled || []),
  ];
  let uniqueDates: any = [];
  metaData.forEach((item) => uniqueDates.push(item?.date));

  uniqueDates = [...new Set(uniqueDates)];
  uniqueDates.forEach((date: any) => {
    let temp = {};
    //get Bound for that very date
    let bound = metaData?.find((item: any) => item?.date == date)?.Bounds;
    // Filter out beforeBreakfast value
    let beforeBreakfast = data?.beforeBreakfast.filter((item: any) => {
      if (formatDate(item.date) === formatDate(date)) {
        return item;
      }
    });
    beforeBreakfast = beforeBreakfast.length
      ? beforeBreakfast[0].glucose.value
      : null;

    // Filter out afterBreakfast value
    let afterBreakfast = data?.afterBreakfast.filter((item: any) => {
      if (formatDate(item.date) === formatDate(date)) {
        return item;
      }
    });
    afterBreakfast = afterBreakfast.length
      ? afterBreakfast[0].glucose.value
      : null;

    // Filter out beforeDinner value
    let beforeDinner = data?.beforeDinner?.filter((item: any) => {
      if (formatDate(item.date) === formatDate(date)) {
        return item;
      }
    });
    beforeDinner = beforeDinner.length ? beforeDinner[0].glucose.value : null;

    //Filter out Unscheduled value
    let unScheduled = data?.unScheduled?.filter((item: any) => {
      if (formatDate(item.date) === formatDate(date)) {
        return item;
      }
    });
    unScheduled = unScheduled.length ? unScheduled[0].glucose.value : null;
    temp = {
      day: formatDate(date),
      glucoseBound: getBoundsForSingleReading(
        getThresholdsAccToUser(bound, getAppUser()),
        "glucose"
      ),
      beforeBreakfast,
      afterBreakfast,
      beforeDinner,
      unScheduled,
    };
    result.push(temp);
  });
  var abc = getFinalPlotData(result, args);
  return abc;
};

export const getPulseOxChartData = (data: any, args: any) => {
  var x = data?.map(({ worstData }: any) => {
    let { Bounds } = worstData;
    return {
      day: moment.utc(worstData?.date).local().format("MMM DD"),
      spo2: worstData?.spo2?.value,
      pulse: worstData?.heartRate?.value,
      pulseBound: getBoundsForSingleReading(
        getThresholdsAccToUser(Bounds, getAppUser()),
        "pulse"
      ),
      spo2Bound: getBoundsForSingleReading(
        getThresholdsAccToUser(Bounds, getAppUser()),
        "spo2"
      ),
    };
  });

  var final = getFinalPlotData(x, args);
  return final;
};

export const getWeightChartData = (data: any[], args: any) => {
  var temp = data?.map(({ worstData }: any) => {
    let { Bounds } = worstData;
    return {
      day: moment.utc(worstData?.date).local().format("MMM DD"),
      weight: worstData?.weight?.value,
      weightChange: worstData[getActiveBoundforWeight(worstData)],
      heartFailure: worstData?.heartFailure,
      obesity: worstData?.obesity,
      weightBound: getBoundsForSingleReading(
        Bounds ? getThresholdsAccToUser(Bounds, getAppUser()) : [],
        getActiveBoundforWeight(worstData)
      ),
    };
  });

  var final = getFinalPlotData(temp, args);
  return final;
};

export const getTemperatureChartData = (data: any[], args: any) => {
  var temp = data?.map(({ worstData }: any) => {
    let { Bounds } = worstData;
    return {
      day: moment.utc(worstData?.date).local().format("MMM DD"),
      temperature: worstData?.temperature?.value,
      temperatureBound: getBoundsForSingleReading(
        getThresholdsAccToUser(Bounds, getAppUser()),
        "temperature"
      ),
    };
  });

  var final = getFinalPlotData(temp, args);
  return final;
};

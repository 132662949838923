import { Card, Checkbox, Col, Divider, Radio, Row, Space, Typography } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateThresholdForPatientByAssignee } from "../../../../../../redux/actions/hrm/hrmActions";
import { AuthStateSelector } from "../../../../../../redux/reducers/authReducer/authReducer";
import { patientStateSelector } from "../../../../../../redux/reducers/hrm/hrmReducer";
import { convertLbsTokgs, getFullName, removeEmptyKeys } from "../../../../../../utility/utils";
import {
  downArrow,
  upArrow,
} from "../../../../../images";
import { ThresholdBounds } from "../../../../../models/patient/patientThresholds";
import Button from "../../../common/button";
import SelectInput from "../../../common/selectInput";
import "./patientWeight.less";

const PatientWeight = (props: any) => {
  const { el, isEditable } = props;
  const [weightDiagno, setweightDiagno] = useState<any>(el);
  const [isKg, setIsKg] = useState(true);
  const [radioValue, setRadioValue] = useState("lbs");
  const [selectedFormat, setFormat] = useState(props.el.weightFormat)
  const [maxwt, setMaxWt] = useState(el.maxwt);
  const [valueCheck , setValueCheck] = useState<any>()
  const [heartWeight, setHeartWeight] = useState<ThresholdBounds| any>({} as ThresholdBounds);
  const [obesityWeight, setObesityWeight] = useState<ThresholdBounds| any>({} as ThresholdBounds);
  const [minWt, setMinWt] = useState(el.minWt);
  const [ weight, setWeightBound] = useState<ThresholdBounds>({} as ThresholdBounds);
  const [weightPercent, setWeightPercent] = useState<ThresholdBounds>({} as ThresholdBounds);
  const [weightDays , setWeightDays] = useState<ThresholdBounds |any>({
      intraPerc: el?.intraPerc,
      twoDayPerc: el?.twoDayPerc,
      fiveDayPerc: el?.fiveDayPerc,
      fourteenDayPerc: el?.fourteenDayPerc,
      thirtyDayPerc: el?.thirtyDayPerc,
      adherencePerc: el?.adherencePerc,
      compliancePerc: el?.compliancePerc
    })

  const { appUser } = useSelector(AuthStateSelector)
  const {isUpdated} = useSelector(patientStateSelector);
const dispatch = useDispatch()
  useEffect(() => {
    const wtlbs = el?.patientThresholdBounds?.filter((el: any) => {
      return el?.boundType === "weight"
    });
    setWeightBound(wtlbs[0]);
    
  }, [props])


  useEffect(() => {
    const wtlbs = el?.patientThresholdBounds?.filter((el: any) => {
      return el?.boundType === "obesity"
    });
    const wtHeart = el?.patientThresholdBounds?.filter((el: any) => {
      return el?.boundType === "heartFailure"
    });
    setHeartWeight(wtHeart[0]);
    setObesityWeight(wtlbs[0]);
    
  }, [props])


  const handleCheckBox = (checkedValues: any) => {
    setValueCheck(checkedValues?.map((value: any) => {
      if (value == "Heart Failure") {
        setHeartWeight({ ...heartWeight, isApplied: true })
        setObesityWeight({ ...obesityWeight, isApplied: false })
        return "Heart Failure"
      } else {
        setObesityWeight({ ...obesityWeight, isApplied: true })
        setHeartWeight({ ...heartWeight, isApplied: false })
        return "Obesity"
      }
    })[0])
  }
  
  useEffect(()=>{
    if(heartWeight?.isApplied == true){
      setValueCheck(["Heart Failure"])
    }else if(obesityWeight?.isApplied == true){
      setValueCheck(["Obesity"])
    }
  },[obesityWeight, heartWeight])

  const [editView, setEditView] = useState(true);
  const selectValue = [
    {
      text: "Standard Threshold",
      value: "Standard Threshold",
    },
    {
      text: "Custom Threshold",
      value: "Custom Threshold",
    },
  ];

 const handleWeightDays = (e:any)=>{
   var {name, value} = e.target;
  if(value>=0){
    setWeightDays({...weightDays, [name]: value?parseFloat(value):""});
  }
 }
 const handleWeightDaysAdhrenc = (e:any)=>{
  var {name, value} = e.target;
  const x =  Math.max(1, Math.min(100, parseInt(value?value:"")));
  setWeightDays({...weightDays, [name]: x})
}


 const handleHeartPercent = (e:any)=>{
  var {name,value} = e.target;
  const y=parseFloat(value);
  const x=Math.floor(y * 100) / 100;
  setHeartWeight({...heartWeight, [name]: x});
}
const handleObesityPercent = (e:any)=>{
  var {name,value} = e.target;
  const y=parseFloat(value);
  const x=Math.floor(y * 100) / 100;
  //console.log(x);
  setObesityWeight({...obesityWeight, [name]:x});
}



   

  const handleSubmit = () => {  
    dispatch(updateThresholdForPatientByAssignee({id:el.id, ...removeEmptyKeys(weightDays), bounds:[removeEmptyKeys(heartWeight), removeEmptyKeys(obesityWeight)]}))

  };
  const handleCancel =()=>{
    setEditView(true)
    setWeightDays({
      intraPerc: el?.intraPerc,
      twoDayPerc: el?.twoDayPerc,
      fiveDayPerc: el?.fiveDayPerc,
      fourteenDayPerc:el?.fourteenDayPerc,
      thirtyDayPerc: el?.thirtyDayPerc,
      adherencePerc: el?.adherencePerc,
      compliancePerc: el?.compliancePerc
    })
    const wtlbs = el?.patientThresholdBounds?.filter((el: any) => {
      return el?.boundType === "obesity"
    });
    const wtHeart = el?.patientThresholdBounds?.filter((el: any) => {
      return el?.boundType === "heartFailure"
    });
    setHeartWeight(wtHeart[0]);
    setObesityWeight(wtlbs[0]);
  }

  
useEffect(()=>{

setMaxWt(Math.round(parseInt(weight?.maxBound) * 100) / 100 || 0)
setMinWt(Math.round(parseInt(weight?.minBound) * 100) / 100 || 0)


},[weight?.maxBound, weight?.minBound])
// let intr:any
  const handleRadioButton = async (e: any) => {
    setRadioValue(e.target.value)
    let hfMax : any;
    let hfMin: any;
    let obMax: any;
    let obMin: any;
    let intr:any;
    let twoDays: any
    let fiveDays : any
    let fourteenDays : any
    let thirtyDays : any
    if (e.target.value === "kgs") {
      hfMax = await convertLbsTokgs(parseInt(heartWeight?.maxBound));
      hfMin = await convertLbsTokgs(parseInt(heartWeight?.minBound));
      obMax = await convertLbsTokgs(parseInt(obesityWeight?.maxBound));
      intr = await convertLbsTokgs(parseFloat(weightDays?.intraPerc));
      twoDays =  await convertLbsTokgs(parseFloat(weightDays?.twoDayPerc));
      fiveDays = await convertLbsTokgs(parseFloat(weightDays?.fiveDayPerc)); 
       fourteenDays = await convertLbsTokgs(parseFloat(weightDays?.fourteenDayPerc)); 
       fourteenDays = await convertLbsTokgs(parseFloat(weightDays?.fourteenDayPerc)); 
       thirtyDays =  await convertLbsTokgs(parseFloat(weightDays?.thirtyDayPerc)); 

      obMin = await convertLbsTokgs(parseInt(obesityWeight?.minBound));
    } else if(e.target.value === "lbs") {
      hfMax = el?.patientThresholdBounds?.find((el: any) => {
        return el?.boundType === "heartFailure"
      })?.maxBound;
      hfMin = el?.patientThresholdBounds?.find((el: any) => {
        return el?.boundType === "heartFailure"
      })?.minBound;
      obMax = 
      el?.patientThresholdBounds?.find((el: any) => {
        return el?.boundType === "obesity"
      })?.maxBound;
      obMin = el?.patientThresholdBounds?.find((el: any) => {
        return el?.boundType === "obesity"
      })?.minBound;
      intr = el?.intraPerc
      twoDays = el?.twoDayPerc
      fiveDays = el?.fiveDayPerc
      fourteenDays = el?.fourteenDayPerc
      thirtyDays = el?.thirtyDayPerc
      // console.log(intr);
      // intr = el.intraPerc((el:any)=>{
      //   return el?.boundType === "obesity"
      // })?.intraPerc

    
    }
    setHeartWeight({...heartWeight,  maxBound: parseFloat(hfMax).toFixed(2), minBound: parseFloat(hfMin).toFixed(2)})
   // setHeartWeight({...heartWeight, maxBound: hfMax.toString(), minBound:hfMin.toString()})
    setWeightDays({...weightDays, intraPerc: parseFloat(intr).toFixed(2) , twoDayPerc: parseFloat(twoDays).toFixed(2)
  , fiveDayPerc: parseFloat(fiveDays).toFixed(2) ,fourteenDayPerc: parseFloat(fourteenDays).toFixed(2) ,thirtyDayPerc: parseFloat(thirtyDays).toFixed(2)})
    setObesityWeight({...obesityWeight, maxBound: parseFloat(obMax).toFixed(2), minBound: parseFloat(obMin).toFixed(2)})
  }

  // useEffect(()=>{
  //   setWeightDays({...weightDays, ["intraPerc"]:intr})
  // },[weightDays])


  useEffect(()=>{
    if(isUpdated.isSuccess){
      setEditView(true)
    }
    },[isUpdated.isSuccess, isUpdated.isError])

  return (
    <div className="bpCardContainer">
      <Card className="bpCard">
        <Row>
          <Col  md={12} lg={4} xl={4} xxl={4} className="BPpatientName">
            <Row>
            <Col span={16}>
            <Typography className="drTxt f-16">{
              el.ThresholdAssigner ?
                getFullName(el.ThresholdAssigner?.title,
                  el?.ThresholdAssigner?.firstName,
                  el?.ThresholdAssigner?.middleName,
                  el?.ThresholdAssigner?.lastName)
                :
                getFullName(appUser?.title, appUser?.firstName, appUser?.middleName, appUser?.lastName)
            }</Typography>
            <Typography className="bpCardTxt">{el?.ThresholdAssigner?.providerType}{el?.ThresholdAssigner?.specialtyType? "," :null}</Typography>
            <Typography className="bpCardTxt">
              {el?.ThresholdAssigner?.specialtyType}
            </Typography>
            </Col>
            </Row>
          </Col>
          {editView ? (
            <>
              <Col  md={12} lg={8} xl={8} xxl={8}>
                <Row>
                  <Col span={20}>  
                  <div className="weightIndication">
                    <Row>
                      <Col span={14}>
                      <p className="pulseTxt f-12">Indication</p>
                      </Col>
                      <Col  span={10}>
                      <p className="pulseTxt f-12">Weight Change ({radioValue})</p>
                      </Col>
                    </Row>
                

                  </div>
                  </Col>
                </Row>
              <Row>
                  <Col span={12}>
                  
                    <Row>
                      <Col md={24} lg={20} xl={20} >
                      <div className="PHeartFObesity" >
                      <Checkbox.Group
                      key={valueCheck?.toString()}
                      defaultValue={valueCheck}
                  >
                    <Space direction="vertical">
                    <Checkbox value="Heart Failure" disabled={true} className="heartFailure">
                    Heart Failure (24 Hrs)
                    </Checkbox>
                    <Checkbox value="Obesity" disabled={true} className="Obesity">
                    Obesity
                    </Checkbox>
                    </Space>
                  </Checkbox.Group>
                  </div>
                      </Col>                  
                      <Col md={0} lg={4} xl={4}></Col>
                    </Row>
                  </Col>
                  <Col span={10} className="boredrRight1 hideDivider">
                  
                    <Row>
                      <Col md={9} lg={7} xl={7} xxl={7}  className="boredrRight">
                        <p className="pulseTxt f-12">
                          Loss <img src={downArrow} className="downArrow" />
                        </p>
                        <p className="pulseNmbr f-12">
                        {parseFloat(heartWeight?.minBound).toFixed(2)}
                        </p>
                        <p className="pulseNmbr f-12">
                        {parseFloat(obesityWeight?.minBound).toFixed(2)}
                        </p>
                      </Col>
                      <Divider style={{height:"100%"}} type="vertical"/>
                      <Col md={9} lg={7} xl={7} xxl={7} >
                        <p className="pulseTxt f-12">
                          Gain <img src={upArrow} className="downArrow" />
                        </p>
                        <p className="pulseNmbr f-12">
                          {parseFloat(heartWeight?.maxBound).toFixed(2)}
                        </p>
                        <p className="pulseNmbr  f-12">
                        {parseFloat(obesityWeight?.maxBound).toFixed(2)}
                        </p>
                      </Col>
                      <Col span={8}></Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col  md={12} lg={7} xl={7} xxl={7} className="mborder3Reverse">
                <Row>
                  <Col md={8} lg={7} xl={7}>
                    <p className="bloodPressureTxt f-12">Intraday</p>
                    <p className="systolicTxtNmbr f-12">{weightDays?.intraPerc}</p>
                    <p className="bloodPressureTxt f-12">14 Day</p>
                    <p className="systolicTxtNmbr f-12">
                      {weightDays?.fourteenDayPerc}
                    </p>
                  </Col>
                  <Col  md={8} lg={7} xl={7}>
                    <p className="bloodPressureTxt f-12">2 Day</p>
                    <p className="systolicTxtNmbr f-12">{weightDays?.twoDayPerc}</p>
                    <p className="bloodPressureTxt f-12">30 Day</p>
                    <p className="systolicTxtNmbr f-12">
                      {weightDays?.thirtyDayPerc}
                    </p>
                  </Col>
                  <Col  md={8} lg={8} xl={8} className="">
                    <p className="bloodPressureTxt f-12">5 Day</p>
                    <p className="systolicTxtNmbr f-12">{weightDays?.fiveDayPerc}</p>
                    <p className="bloodPressureTxt f-12">Adherence % </p>
                    <p className="systolicTxtNmbr f-12">{weightDays?.adherencePerc}</p>
                  </Col>
                </Row>
              </Col>
              <Col  md={12} lg={5} xl={5} xxl={5} className="mborder4Reverse">
                <Row>
               
                  <Col span={20} style={{textAlign:'center'}}>

                  <Radio.Group defaultValue={"lbs"} value={radioValue} onChange={(e) => handleRadioButton(e)}
                    >
                      <Radio value={"kgs"}>
                        <span className="fRadioGroup f-14">kgs</span>
                      </Radio>
                      <Radio value={"lbs"}>
                        <span className="fRadioGroup f-14">lbs</span>
                      </Radio>
                    </Radio.Group>
                  </Col>
                
                  <Col span={4} style={{textAlign:'right'}}>
                    {isEditable?
                    <div>
                      {editView ? (
                        <span
                          className="material-icons-outlined notEditpencilIcon"
                          onClick={() => {
                            editView ? setEditView(false) : setEditView(true);
                          }}
                        >
                          edit
                        </span>
                      ) : null}
                    </div>
                    :(<span
                      className="material-icons-outlined pencilIcon"
                    >
                      edit
                    </span>)}
                  </Col>
                 
                  <Col span={24} className="thresholdSelect">
                    <SelectInput
                      placeholder="select"
                      name="role"
                      className="card-dropdown with-search"
                      bordered={true}
                      optionValue={selectValue}
                      disabled={editView}
                    />
                  </Col>
                </Row>
              </Col>
            </>
          ) : (
            <>
              <Col md={17} lg={15} xl={15} xxl={15}>
                <Row gutter={[20, 30]}>
                  <Col  md={12} lg={12} xl={8} xxl={6}>
                    <p className="diastolicTxt f-12">Indication</p>
                    <Row gutter={[20, 30]}>
                      <Col md={20} lg={16} xl={16} >
                      <div className="PHeartFObesity editView" >
                      <Checkbox.Group 
                      defaultValue={valueCheck}
                      onChange={handleCheckBox}
                      value = {valueCheck}
                  >
                 
                    <Checkbox value="Heart Failure" className="heartFailure">
                    Heart Failure (24 Hrs)
                    </Checkbox>
                    <Checkbox value="Obesity" className="Obesity">
                    Obesity
                    </Checkbox>
                  </Checkbox.Group>
                  </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col  md={12} lg={12} xl={8} xxl={6}>
                    <p className="diastolicTxt f-12">Weight Change ({radioValue})</p>
                   
                    <Row gutter={[20, 30]}>
                    <Col span={7} >
                        <p className="pulseTxt f-12">
                          Loss <img src={downArrow} className="downArrow" />
                        </p>
                        <input
                          placeholder="Low"
                          name="minBound"
                          value={heartWeight?.minBound}
                          type="number"
                          className="inp"
                          onChange={handleHeartPercent}
                        />
                          <input
                          placeholder="Low"
                          name="minBound"
                          type = "number"
                          className="inp"
                          value={obesityWeight?.minBound}
                          onChange={handleObesityPercent}

                        />
                      </Col>
                      <Divider style={{height:"100%"}} type="vertical"/>
                      <Col span={7}>
                        <p className="pulseTxt f-12">
                          Gain <img src={upArrow} className="downArrow" />
                        </p>
                     
                        <input
                          placeholder="High"
                          type="number"
                          name="maxBound"
                          className="inp"
                          value={heartWeight?.maxBound}
                          onChange={handleHeartPercent}
                        />
                         <input
                          placeholder="High"
                          type="number"
                          name="maxBound"
                          className="inp"
                          value={obesityWeight?.maxBound}
                          onChange={handleObesityPercent}
                          
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col span={24} className="diastolicColumn weightColumn">
                    <Row gutter={[20, 30]}>
                      <Col md={7} lg={6} xl={4} xxl={3}>
                      <p className="diastolicTxt f-12">Intraday</p>
                        <input
                          placeholder="number"
                          type="number"
                          className="inp"
                          name="intraPerc"
                          value={weightDays?.intraPerc}
                          onChange={handleWeightDays}
                        />
                      </Col>
                      <Divider className="divider dividerHeight" type="vertical"/>
                      <Col  md={6} lg={6} xl={4} xxl={3}>
                      <p className="diastolicTxt f-12">2 day</p>
                        <input
                          placeholder="number"
                          type="number"
                          className="inp"
                          name="twoDayPerc"
                          value={weightDays?.twoDayPerc}
                          onChange={handleWeightDays}
                        />
                      </Col>
                      <Divider className="divider dividerHeight" type="vertical"/>
                      <Col  md={6} lg={6} xl={4} xxl={3}>
                      <p className="diastolicTxt f-12">5 day</p>
                        <input
                          placeholder="number"
                          type="number"
                          className="inp"
                          name="fiveDayPerc"
                          value={weightDays?.fiveDayPerc}
                          onChange={handleWeightDays}
                        />
                      </Col>
                      <Divider className="divider dividerHeight hideDivider" type="vertical"/>
                      <Col  md={6} lg={6} xl={4} xxl={3}>
                      <p className="diastolicTxt f-12">14 day</p>
                        <input
                          placeholder="number"
                          type="number"
                          className="inp"
                          name="fourteenDayPerc"
                          value={weightDays?.fourteenDayPerc}
                          onChange={handleWeightDays}

                        />
                      </Col>
                      <Divider className="divider dividerHeight" type="vertical"/>
                      <Col  md={6} lg={6} xl={4} xxl={3}>
                      <p className="diastolicTxt f-12">30 day</p>
                        <input
                          placeholder="number"
                          type="number"
                          className="inp"
                          name="thirtyDayPerc"
                          value={weightDays?.thirtyDayPerc}
                          onChange={handleWeightDays}

                        />
                      </Col>
                      <Divider className="divider dividerHeight" type="vertical"/>
                      <Col  md={8} lg={6} xl={6} xxl={4} >
                      <p className="diastolicTxt f-12">Adherence (1-100)%</p>
                        <input
                          placeholder="1%-100%"
                          type="number"
                          className="inp"
                          name="adherencePerc"
                          value={weightDays?.adherencePerc}
                          onChange={handleWeightDaysAdhrenc}

                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col md={7} xl={5} lg={5} xxl={5}>
                <Row>
               
                  <Col span={20} style={{textAlign:'center'}}>
                  <Radio.Group
                  defaultValue={"lbs"}
                      value={radioValue} onChange={handleRadioButton}
                    >
                      <Radio value={"kgs"}>
                        <span className="fRadioGroup f-14">kgs</span>
                      </Radio>
                      <Radio value={"lbs"}>
                        <span className="fRadioGroup f-14">lbs</span>
                      </Radio>
                    </Radio.Group>
                  </Col>
                </Row>
              <Row>
              
                  <Col span={24} className="thresholdSelect">
                  <SelectInput
                  placeholder="select"
                  name="role"
                  className="card-dropdown with-search"
                  bordered={true}
                  optionValue={selectValue}
                />
                  </Col>
                </Row>
           

                <Row className="thresholdSaveBtn" gutter={[20, 30]} justify="end">
                  <Col md={10} lg={9} xl={10}>
                  <Button
                      className="filter"
                      type="secondary"
                      htmlType="search"
                      onClick={handleCancel}
                    >
                    cancel
                    </Button>
                  </Col>
                  <Col md={14} lg={15} xl={14}>
                    <Button
                      className="filter"
                      type="secondary"
                      htmlType="search"
                      onClick={handleSubmit}
                    >
                      save changes
                    </Button>
                  </Col>
                </Row>
              </Col>
            </>
          )}
        </Row>
      </Card>
    </div>
  );
};
export default PatientWeight;

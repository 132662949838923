export const Messages = {
    AUTH_FAILED: `Entered Username or Password is not valid. Please try again.`,
    EMAIL_BLOCKED: "Your account is inactive. Please contact administrator.",
    CHECK_EMAIL_FAILED: "User not found. Please try again",
    USER_NOT_FOUND: "No user found with requested username. Please try again.",
    TOKEN_EXPIRED: "No user found for the requsted token, please try again.",
    PWD_CREATED_SUCCESS: "Password created successfully!", 
    PWD_DOESNOT_MATCH: "Password does not match",
    ORGANIZATION_EXIST: "Organization/Centre with this name already exists.",
    SPECIALTY_EXIST: "Speciality with this name already exists.",
    USER_DEACTIVATED: "Your account is deactivated, kindly contact your admin to activate your account.",
    START_DATE_ERROR: "Start date can not be after end date",
    END_DATE_ERROR: "End date can not be before start date",
    PWD_CHANGED: "Password changed successfully.",
    SKIP_COUNT_MESSAGE:"Schedule creator will be notified after the patient will misses the specified number of scheduled events."
      
};

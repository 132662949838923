import { Tooltip } from "antd";
import { Children } from "react";
import "./toolTip.less";

const CustomTooltip = (props: any) => {
  const { content, title, color, overlayClassName, children , placement,heading} = props;
  const getTooltipContent=()=>{
    if(heading){
      return(
        <>
        <span><b>{heading}</b></span><span>{title}</span>
        </>
      )
    }
    else{
      return title
    }
  }
  return (
    <>
          <Tooltip
            title={getTooltipContent()}
            color={color}
            placement={placement}
            overlayClassName={
              overlayClassName ? `tb-0 ${overlayClassName}` : "tb-0"
            }
          >
            {children}
          </Tooltip>
    </>
  );
};

export default CustomTooltip;

import { Card, Col, Row, Spin } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { fetchSchedulePlan } from "../../../../../redux/actions/hrm/hrmActions";
import { patientStateSelector } from "../../../../../redux/reducers/hrm/hrmReducer";
import { PatientStateSelector } from "../../../../../redux/reducers/patient/patientReducer";
import { biometricNamesObject } from "../../../../constants/constants";
import { Biometricname, Months } from "../../../../constants/enums";
import {
  bpReadingIcon,
  glucoMeter,
  leftFilled,
  rightFilled,
  spiro,
  spo2Icon,
  temperature,
  weightReadingsIcon,
} from "../../../../images";
import { CompWrapper } from "../../common/contentWrapper";
import CustomTooltip from "../../common/toolTip";
import { PatientDetailCard } from "../detailsCard";
import { MonthSchedule } from "../scheduleCalender/monthSchedule/monthScheduleCalendar";
import { setMonthlyScheduleData } from "../scheduleCalender/scheduleDataTranformers";

export const AdherenceReview = () => {
  const location = useLocation<any>();
  const config = location?.state ? location?.state : undefined;
  const { schedulePlan, formState } = useSelector(patientStateSelector);
  const { adhrence, compliance } = useSelector(PatientStateSelector);
  const todayDate = moment().date();
  const year = moment().year();
  const [selectedDay, setSelectedDay] = useState<any>(
    moment(`${year}-${config?.year}-${todayDate}`, "YYYY-MMM-DD")
  );
  const [span, setSpan] = useState<any>({});
  const [selectedFormat, setSelectedFormat] = useState<any>("DD MMMM YYYY");
  const [selectedFormat1, setSelectedFormat1] = useState<any>("MMMM YYYY");
  const [tableData, setTableData] = useState<any>([]);
  const [days, setDays] = useState([]);
  const { selectedPatient } = useSelector(PatientStateSelector);
  const dispatch = useDispatch();
  const [selectedView, setSelectedView] = useState<"adherence" | "compliance">(
    config?.selectedview
  );
  const [percentage, setPercentage] = useState(config);
  const setPrev = () => {
    setSelectedDay((selectedDay: any) =>
      selectedDay.clone().subtract(1, "month")
    );
  };
  const getDate = () => {
    return (
      <span className="dateFormat f-20">
        {selectedDay.clone().format("MMMM YYYY")}
      </span>
    );
  };
  const setNext = () => {
    setSelectedDay((selectedDay: any) => selectedDay.clone().add(1, "month"));
  };
  const getTable = () => {
    return <MonthSchedule selectedMonth={selectedDay} data={tableData} />;
  };
  useEffect(() => {
    dispatch(
      fetchSchedulePlan({
        patientId: selectedPatient?.id,
        fromDate: moment(selectedDay).startOf("month").format("YYYY-MM-DD"),
        toDate: moment(selectedDay).endOf("month").format("YYYY-MM-DD"),
      })
    );
  }, [selectedDay, selectedPatient]);
  useEffect(() => {
    return setTableData(setMonthlyScheduleData(schedulePlan));
  }, [schedulePlan]);
  const getPercentage = (
    data: any[],
    datatype: "adherencePerc" | "compliancePerc"
  ) => {
    let formatted: any[] = [];

    data?.map((element: any) => {
      const exist: any = formatted.findIndex(
        (el: any) => el.year === Months[element?.singleMonth - 1]
      );
      if (exist >= 0) {
        const key: string = element?.biometricName;
        const updatedEl: any = { ...formatted[exist] };
        updatedEl[key] = Math.round(element[datatype] * 10) / 10 || 0;
        formatted.splice(exist, 1, updatedEl);
      } else {
        const obj: any = {};
        const key: string = element?.biometricName;
        obj[key] = Math.round(element[datatype] * 10) / 10 || 0;
        obj["year"] = Months[element?.singleMonth - 1];
        formatted.push(obj);
      }
    });
    let x = formatted?.find(
      (el: any) => selectedDay.clone().format("MMM") === el?.year
    );
    var finalObj = x
      ? x
      : {
          bloodpressure: 0,
          glucose: 0,
          pulseox: 0,
          spirometry: 0,
          temperature: 0,
          weight: 0,
        };

    return finalObj;
  };
  useEffect(() => {
    if (selectedView == "adherence") {
      setPercentage(getPercentage(adhrence, "adherencePerc"));
    } else if (selectedView == "compliance") {
      setPercentage(getPercentage(compliance, "compliancePerc"));
    }
  }, [selectedView, selectedDay]);

  return (
    <>
      <Row>
        <Col span={24}>
          <PatientDetailCard
            patient={selectedPatient}
            showButton
            selectedItem={selectedView}
            setSelectedItem={setSelectedView}
          />
        </Col>
      </Row>

      <Spin spinning={formState.loading}>
        <Row className="MonthRep">
          <CompWrapper observeOn="patientDetailsCard">
            <Col span={24}>
              <Card>
                <Row justify="space-between">
                  <Col span={12} md={17} lg={15} xl={12}>
                    <Row>
                      <Col
                        span={12}
                        md={9}
                        lg={9}
                        xl={12}
                        className="scheduleTitle"
                      >
                        <p>{selectedView} REVIEW</p>
                      </Col>
                      <Col span={12} md={15} lg={15} xl={12}>
                        <Row>
                          <Col span={4} className="adhColAlign">
                            {" "}
                            <CustomTooltip
                              title={biometricNamesObject[Biometricname.BP]}
                              color="#FFFFFF"
                              content="show"
                              placement="top"
                            >
                              <img src={bpReadingIcon}></img>
                            </CustomTooltip>
                            <p className="percntIcn">
                              {percentage?.bloodpressure
                                ? percentage?.bloodpressure + "%"
                                : "NA"}
                            </p>
                          </Col>
                          <Col span={4} className="adhColAlign">
                            <CustomTooltip
                              title={biometricNamesObject[Biometricname.GLUCO]}
                              color="#FFFFFF"
                              content="show"
                              placement="top"
                            >
                              <img src={glucoMeter}></img>
                            </CustomTooltip>
                            <p className="percntIcn">
                              {percentage?.glucose
                                ? percentage?.glucose + "%"
                                : "NA"}
                            </p>
                          </Col>
                          <Col span={4} className="adhColAlign">
                            <CustomTooltip
                              title={biometricNamesObject[Biometricname.PULSE]}
                              color="#FFFFFF"
                              content="show"
                              placement="top"
                            >
                              <img src={spo2Icon}></img>
                            </CustomTooltip>
                            <p className="percntIcn">
                              {percentage?.pulseox
                                ? percentage?.pulseox + "%"
                                : "NA"}
                            </p>
                          </Col>
                          <Col span={4} className="adhColAlign">
                            <CustomTooltip
                              title={biometricNamesObject[Biometricname.WEIGHT]}
                              color="#FFFFFF"
                              content="show"
                              placement="top"
                            >
                              <img src={weightReadingsIcon}></img>
                            </CustomTooltip>
                            <p className="percntIcn">
                              {percentage?.weight
                                ? percentage?.weight + "%"
                                : "NA"}
                            </p>
                          </Col>
                          <Col span={4} className="adhColAlign">
                            <CustomTooltip
                              title={biometricNamesObject[Biometricname.SPIRO]}
                              color="#FFFFFF"
                              content="show"
                              placement="top"
                            >
                              <img src={spiro}></img>
                            </CustomTooltip>
                            <p className="percntIcn">
                              {percentage?.spirometry
                                ? percentage?.spirometry + "%"
                                : "NA"}
                            </p>
                          </Col>
                          <Col span={4} className="adhColAlign">
                            <CustomTooltip
                              title={
                                biometricNamesObject[Biometricname.TEMPRATURE]
                              }
                              color="#FFFFFF"
                              content="show"
                              placement="top"
                            >
                              <img src={temperature}></img>
                            </CustomTooltip>
                            <p className="percntIcn">
                              {percentage?.temperature
                                ? percentage?.temperature + "%"
                                : "NA"}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={12} md={7} xl={6} lg={7}>
                    <div className="dateMenu">
                      <img
                        src={leftFilled}
                        onClick={setPrev}
                        className="adhcursor"
                      />
                      {getDate()}
                      <img
                        src={rightFilled}
                        onClick={setNext}
                        className="adhcursor"
                      />
                    </div>
                  </Col>
                </Row>
                <Row style={{ paddingTop: "30px" }}>
                  <Col span={24}>{getTable()}</Col>
                </Row>
              </Card>
            </Col>
          </CompWrapper>
        </Row>
      </Spin>
    </>
  );
};

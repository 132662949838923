import {
  Card,
  Cascader,
  Checkbox,
  Col,
  DatePicker,
  Dropdown,
  Form,
  Menu,
  message,
  Row,
  Spin,
  TimePicker,
} from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { applyPresetSchedule, createScheduleForPatient, fetchPresetScheduleDetail, fetchScheduleForPatient, updatePatientSchedule } from "../../../../../redux/actions/hrm/hrmActions";
import { AuthStateSelector } from "../../../../../redux/reducers/authReducer/authReducer";
import { clearState, patientStateSelector } from "../../../../../redux/reducers/hrm/hrmReducer";
import { PatientStateSelector } from "../../../../../redux/reducers/patient/patientReducer";
import { compareFormValues, getFullName, onMessageClose, removeEmptyKeys } from "../../../../../utility/utils";
import {
  biometricTypeOptions,
  instructionOptions,
  recurrenceOptions,
} from "../../../../constants/constants";
import { Biometricname, CommonIcons, ModalCallBackTypes, ModalPrimaryMessages, ModalType } from "../../../../constants/enums";
import { Messages } from "../../../../constants/messages";
import { filter, up } from "../../../../images";
import Schedule from "../../../../models/schedule/schedule";
import { AppRoutes } from "../../../../router/appRoutes";
import Button from "../../common/button";
import SelectInput from "../../common/selectInput";
import CustomTooltip from "../../common/toolTip";
import { PatientScheduleTable } from "./patientScheduleTable";
import "./schedule.less";
import ConflictModal from "./scheduleConflict/conflictModal";
import { EditScheduleForm } from "./scheduleEdit";
import { clearPresetSchedule } from "../../../../../redux/reducers/hrm/hrmReducer";
const weekDays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
export const PatientSchedule = (props: any) => {
  const { scheduleTableData,providers, setSelectedProvider, selectedProvider, presetSchedules } = props;
  const [tableData, setTableData] = useState<any>([]);
  const [newRow, setNewRow] = useState(false);
  const [checkedAll, setCheckedAll] = useState(false);
  const [newScheduleList, setNewScheduleList] = useState<any[]>([]);
  const [temp, setTemp] = useState<any[]>([])
  const {appUser} = useSelector(AuthStateSelector)
  const {selectedPatient} = useSelector(PatientStateSelector)
  const {isScheduleCreated, isScheduleUpdated, isScheduleDeleted, conflictedSch, presetScheduleDetails, isAssigned} = useSelector(patientStateSelector)
  const dispatch = useDispatch()
  const history = useHistory()
  const [disableForm, setDisableForm] = useState(false)
  const [instructionReqd, setInstructionReqd] = useState(false)
  const [multiple,setMultiple] = useState(false)
  const [filters, setFilters] = useState<any>({});
  const [visibleMenuSettings, setVisible] = useState(false);
  const [showConflict, setShowConflict] = useState(false)
  const [newSchedule, setNewSchedule] = useState<Schedule | any>({
    providerName: getFullName(appUser?.title, appUser?.firstName, appUser?.middleName, appUser?.lastName),
    name: "",
    biometricType: "",
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
    recurrence: "",
    instruction: "",
    action: "",
    skipcount:0,
  });
  let [check, setCheck] = useState<any[]>([]);
  const [selectedSchedule, setSelectedSchedule] = useState<any>({})
  const [editView, setEditView] = useState(false)
  const [conflicted, setConflicted] = useState<any>()
  const [scheduleData, setScheduleData] = useState<Schedule| any>({});
  const readingData = [
    Biometricname.BP,
    Biometricname.GLUCO,
    Biometricname.PULSE,
    Biometricname.SPIRO,
    Biometricname.TEMPRATURE,
    Biometricname.WEIGHT,
  ];
  const [selectedPreset, setSelectedPreset] = useState("")
  const [applyPresetList, setApplyPresetList] = useState<any>({})
  const [updatedSchList, setUpdatedSchList] = useState<any[]>([])
  const [disabledEndHours, setDisabledEndHours] = useState<any[]>([])
  const [disabledStartHours, setDisabledStartHours] = useState<any[]>([])
  const inputDateFormat = "MM-DD-YYYY"

  const onEditClick = (sch: any)=> {
    setScheduleData({...sch,
      startTime: moment.utc(sch?.startTime, "HH:mm:ss").local().format('HH:mm'),
      endTime: moment.utc(sch?.endTime, "HH:mm:ss").local().format('HH:mm'),
      fromDate: moment.utc(sch?.fromDate+" "+sch?.startTime).local().format("DD MMM YYYY"),
      toDate: moment.utc(sch?.toDate+" "+sch?.endTime).local().format("DD MMM YYYY"),
    }
      )
    setSelectedSchedule({
      id: sch?.id,
      biometricName: sch?.biometricName,
      fromDate: moment.utc(sch?.fromDate+" "+sch?.startTime).local().format("DD MMM YYYY"),
      toDate: moment.utc(sch?.toDate+" "+sch?.endTime).local().format("DD MMM YYYY"),
      startTime:  moment.utc(sch?.startTime, "HH:mm:ss").local().format('HH:mm'),
      endTime:  moment.utc(sch?.endTime, "HH:mm:ss").local().format('HH:mm'),
      recurrence: sch?.recurrence,
      instruction: sch?.instruction,
      skipcount:sch?.skipcount,
    })
    setEditView(true)
  }  
  
  const handleBiometricSelect = (value: any) => {
    if(value == Biometricname.GLUCO){
    setNewSchedule({
      ...newSchedule,
      biometricName: value,
    });
    setInstructionReqd(true)

  }else{
    setNewSchedule({
      ...newSchedule,
      biometricName: value,
    });
    setInstructionReqd(false)

  }
  };
const getCountOption=()=>{
  let x=[]
  for(var i=0;i<=10;i++){
    x.push({text:`${i}`,value:i})
  }
  return x;
}
useEffect(()=>{
  return ()=>{
    dispatch(clearPresetSchedule())
  }
},[])
useEffect(() => {
  window.addEventListener("beforeunload", ()=>dispatch(clearPresetSchedule()));
}, []);
// const setMultipleState = async  (val: boolean) => {
//   setMultiple(val)
//   }
//   const handleReccurrenceSelect = async (value: any[]) => {
//     if((value&& value[0] && value[0]=="daily") || (value?.some((x:any[])=>x?.indexOf("daily")> -1))){
//         setMultiple(false)
//         setNewSchedule({
//             ...newSchedule, 
//             recurrence: ["daily"],
//           })
//     }else{
//         await setMultipleState(true).then(()=>setNewSchedule({
//           ...newSchedule,
//           recurrence: value?.map((s:any)=>{if(s && s!= 'weekly')return s[1]}),
//         }))
//         // if(value[0] == "weekly"){
//         //   setNewSchedule({...newSchedule, recurrence: weekDays})
//         // }else{
          
//         // }
//   }
//   };

const handleReccurrenceSelect = (value: any[]) => {
  if((value&& value[0] && value[0]=="daily") || (value?.some((x:any[])=>x?.indexOf("daily")> -1))){
      setMultiple(false)
      setNewSchedule({
          ...newSchedule, 
          recurrence: ["daily"],
        })
  }else{
      setMultiple(true)
      if(value[0] == "weekly"){
        setNewSchedule({...newSchedule, recurrence: weekDays})
      }else{
        setNewSchedule({
          ...newSchedule,
          recurrence: value?.map((s:any)=>{if(s)return s[1]}),
        });
      }
}
};

  const handleInstruction = (value: any) => {
    setNewSchedule({
      ...newSchedule,
      instruction: value,
    });
  };

  const handleStartTime = (value: any, time: any) => {
    setNewSchedule({
      ...newSchedule,
      startTime: time,
    });
  };

  const handleEndTime = (value: any, time: any) => {
    setNewSchedule({
      ...newSchedule,
      endTime: time,
    });
  };

const handleSkipCount =(value: any) => {
  setNewSchedule({
    ...newSchedule,
    skipcount: value,
  });
};
 

  function onEndDatePick(date: any, dateString: any) {
    setNewSchedule({
      ...newSchedule,
      toDate: dateString,
    });
  }
 
  function onStartDatePick(date: any, dateString: any) {
    
    setNewSchedule({
      ...newSchedule,
      fromDate: dateString,
    });
  }
    
  


  const handleClick = () => {
    setNewRow(true);
  };
  const isConflicted = async (schedule: any) => {
    var x: boolean = true
    // console.log("Before conversion",moment(schedule?.fromDate+" "+schedule?.startTime,`${inputDateFormat} HH:mm`).utc())
    // var newSchStartUTC = moment(schedule?.fromDate+" "+schedule?.startTime,`${inputDateFormat} HH:mm`).utc().format(inputDateFormat)
    // var newSchEndUTC = moment(schedule?.fromDate+" "+schedule?.endTime,`${inputDateFormat} HH:mm`).utc().format(inputDateFormat)
    var getCommonBiometricSch: any[]
    if(updatedSchList?.some((d:any)=>{return d?.biometricName === schedule?.biometricName})){
      getCommonBiometricSch = updatedSchList
    }else{
      getCommonBiometricSch = scheduleTableData?.filter((sch:any)=>sch?.biometricName === schedule?.biometricName)
      setUpdatedSchList(getCommonBiometricSch)
    }

    if(getCommonBiometricSch?.length){
    for await (const oldSch of getCommonBiometricSch) {
      // console.log("NEw Schedule", schedule)
      // console.log("old Schedule", moment.utc(oldSch?.startTime, "HH:mm").local().format('HH:mm'))
      var olSchStartTime =  moment.utc(oldSch?.startTime, "HH:mm").local().format('HH:mm')
      var oldSchEndTime =  moment.utc(oldSch?.endTime, "HH:mm").local().format('HH:mm')


    var newSchStartUTC = moment(schedule?.fromDate+" "+olSchStartTime,`${inputDateFormat} HH:mm`).utc().format(inputDateFormat)
    var newSchEndUTC = moment(schedule?.fromDate+" "+oldSchEndTime,`${inputDateFormat} HH:mm`).utc().format(inputDateFormat)
    // console.log("New schedule start: ", moment(schedule?.fromDate+" "+schedule?.startTime, `${inputDateFormat} HH:mm`))
    // console.log("New schedule end: ",moment(schedule?.fromDate+" "+schedule?.endTime, `${inputDateFormat} HH:mm`))
    // console.log("OLD schedule start: ", moment.utc(newSchStartUTC+" "+oldSch?.startTime, "MM-DD-YYYY HH:mm:ss").local())
    //       console.log("OLD schedule end: ", moment.utc(newSchStartUTC+" "+oldSch?.endTime, "MM-DD-YYYY HH:mm:ss").local())
      if(
        moment(schedule?.fromDate+" "+schedule?.startTime, `${inputDateFormat} HH:mm`).isAfter(moment.utc(oldSch?.toDate+" "+oldSch?.endTime, 'YYYY-MM-DD HH:mm').local()) 
        ||
        moment(schedule?.toDate+" "+schedule?.endTime, `${inputDateFormat} HH:mm`).isBefore(moment.utc(oldSch?.fromDate+" "+oldSch?.startTime,'YYYY-MM-DD HH:mm').local())
        ){
          x = false
          continue
        }
        else{
          // console.log("OLD schedule start: ", moment.utc(newSchStartUTC+" "+oldSch?.startTime, "MM-DD-YYYY HH:mm:ss").local())
          // console.log("OLD schedule end: ", moment.utc(newSchStartUTC+" "+oldSch?.endTime, "MM-DD-YYYY HH:mm:ss").local())
          // console.log("Condition 1.1:",moment(schedule?.fromDate+" "+schedule?.startTime, `${inputDateFormat} HH:mm`).isBefore(moment.utc(newSchStartUTC+" "+oldSch?.startTime, "MM-DD-YYYY HH:mm:ss").local())
          // )
          // console.log("Condition 1.2:",moment(schedule?.fromDate+" "+schedule?.endTime, `${inputDateFormat} HH:mm`).isSameOrBefore(moment.utc(newSchStartUTC+" "+oldSch?.startTime, "MM-DD-YYYY HH:mm:ss").local())
          // )
          // console.log("condition 2.1 :", moment(schedule?.fromDate+" "+schedule?.startTime, `${inputDateFormat} HH:mm`).isAfter(moment.utc(newSchStartUTC+" "+oldSch?.startTime, "MM-DD-YYYY HH:mm:ss").local())          )

          // console.log("condition 2.2 :", moment(schedule?.fromDate+" "+schedule?.startTime, `${inputDateFormat} HH:mm`).isSameOrAfter(moment.utc(newSchStartUTC+" "+oldSch?.endTime, "MM-DD-YYYY HH:mm:ss").local()))
          if((
            moment(schedule?.fromDate+" "+schedule?.startTime, `${inputDateFormat} HH:mm`).isBefore(moment.utc(newSchStartUTC+" "+oldSch?.startTime, `${inputDateFormat} HH:mm:ss`).local())
              &&
            moment(schedule?.fromDate+" "+schedule?.endTime, `${inputDateFormat} HH:mm`).isSameOrBefore(moment.utc(newSchStartUTC+" "+oldSch?.startTime, `${inputDateFormat} HH:mm:ss`).local())
            )
            ||
          (
            moment(schedule?.fromDate+" "+schedule?.startTime, `${inputDateFormat} HH:mm`).isAfter(moment.utc(newSchStartUTC+" "+oldSch?.startTime, `${inputDateFormat} HH:mm:ss`).local())
            &&
            moment(schedule?.fromDate+" "+schedule?.startTime, `${inputDateFormat} HH:mm`).isSameOrAfter(moment.utc(newSchEndUTC+" "+oldSch?.endTime, `${inputDateFormat} HH:mm:ss`).local()
          ))
          ){
            x = false
            continue
          } else{
            setConflicted(oldSch)
            x = true
            break;
          }
        }
    }
  }else{
    x = false
  }
    return x
  }

  const [allParams, setAllParams] = useState<any>({
    all: false,
    [Biometricname.BP]: false,
    [Biometricname.GLUCO]: false,
    [Biometricname.PULSE]: false,
    [Biometricname.SPIRO]: false,
    [Biometricname.TEMPRATURE]: false,
    [Biometricname.WEIGHT]: false,
  });

  const handleAllCheck = (e: any) => {
    const { name, checked } = e.target;
    if (checked == true) {
      setAllParams({
        all: true,
        [Biometricname.BP]: true,
        [Biometricname.GLUCO]: true,
        [Biometricname.PULSE]: true,
        [Biometricname.SPIRO]: true,
        [Biometricname.TEMPRATURE]: true,
        [Biometricname.WEIGHT]: true,
      });
      setCheck([...readingData]);
      setCheckedAll(true);
      setTableData(scheduleTableData);
    } else {
      setAllParams({
        all: false,
        [Biometricname.BP]: false,
        [Biometricname.GLUCO]: false,
        [Biometricname.PULSE]: false,
        [Biometricname.SPIRO]: false,
        [Biometricname.TEMPRATURE]: false,
        [Biometricname.WEIGHT]: false,
      });
      setCheck([]);
      setCheckedAll(false);
      setTableData(scheduleTableData);
    }
  };

  const handleOtherItemClick = (e: any) => {
    const { name, checked } = e.target;
    var index = check.indexOf(name);
    if ((name != "All" && checked == false) || checkedAll == true) {
      setAllParams({ ...allParams, all: false, [name]: checked });
      setCheckedAll(false);
      check.splice(index, 1);
      var filtered: any = scheduleTableData;
      if(check?.length){
        filtered = filtered.filter((data: any) => {
          for (let i = 0; i <= check.length; i++) {
            if (data.biometricName == check[i]) {
              return data;
            }
          }
        });
      }      
      setTableData(filtered);
    }
    if (checkedAll == false && checked == true) {
      setAllParams({ ...allParams, [name]: checked });
      check.push(name);
      var filtered: any = scheduleTableData;
      filtered = filtered.filter((data: any) => {
        for (let i = 0; i <= check.length; i++) {
          if (data.biometricName == check[i]) {
            return data;
          }
        }
      });
      setTableData(filtered);
    }
  };

  const handleAddSchedule = async () => {
    var getConflict = await isConflicted(newSchedule)
    if(newScheduleList?.some((x: any) =>{return ((x?.biometricName == newSchedule?.biometricName)&&(x?.fromDate == newSchedule?.fromDate)&& ( x?.toDate == newSchedule?.toDate)&&(x?.startTime == newSchedule?.startTime)&&(x?.endTime == newSchedule?.endTime)&&(x?.recurrence == newSchedule?.recurrence))})){
    message.error({content:"Can not create duplicate schedule", duration:0 , key:"appNotification", onClick:onMessageClose, style:{cursor:"pointer"}})
    }
    else if(getConflict){
      setShowConflict(true)
    }
    else {
    setNewScheduleList((newScheduleList: any) => [
      ...newScheduleList,
      {
        ...removeEmptyKeys(newSchedule),
        ["providerName"]: getFullName(
          appUser?.title,
          appUser?.firstName,
          appUser?.middleName,
          appUser?.lastName
        ),
        key:newScheduleList.length+1
      },
    ]);
    setUpdatedSchList((updatedSchList: any) => 
      [...updatedSchList, {...newSchedule,
        fromDate: moment(newSchedule?.fromDate, inputDateFormat).format('YYYY-MM-DD'),
        toDate: moment(newSchedule?.toDate,inputDateFormat).format('YYYY-MM-DD'),
       startTime: moment(newSchedule?.fromDate+" "+newSchedule?.startTime,`${inputDateFormat} HH:mm`).utc().format('HH:mm:ss'),
       endTime: moment(newSchedule?.toDate+" "+newSchedule?.endTime,`${inputDateFormat} HH:mm`).utc().format('HH:mm:ss'),
      }]
      
      )

  }
  };
  const handleRemoveSchedule = (schedule: any) => {
    setNewScheduleList((newScheduleList: any) =>
      newScheduleList?.filter((x: any) => (x?.biometricName != schedule?.biometricName) || (x?.fromDate != schedule?.fromDate)|| ( x?.toDate != schedule?.toDate) ||(x?.startTime != schedule?.startTime)||(x?.endTime != schedule?.endTime)||(x?.recurrence != schedule?.recurrence))
    );
    setUpdatedSchList(
      updatedSchList?.filter((sch:any)=>{
        return(
        (sch?.biometricName != schedule?.biometricName)
         || 
        (sch?.fromDate != moment(schedule?.fromDate, inputDateFormat).format('YYYY-MM-DD'))
        || 
        ( sch?.toDate != moment(schedule?.toDate, inputDateFormat).format('YYYY-MM-DD'))
         ||
         (moment.utc(sch?.startTime, 'HH:mm:ss').local().format('HH:mm') != schedule?.startTime)
         ||
         (moment.utc(sch?.endTime, 'HH:mm:ss').local().format('HH:mm') != schedule?.endTime)
         ||
         (sch?.recurrence != schedule?.recurrence)
      )})
      )
  };
const getRecurrenceValue = () => {
    if(!multiple){
        return [["Daily"]]
    }
}

const deleteSchedule = (sch:any) => {
  dispatch(deleteSchedule(sch))
}
const handleIconClick = (selectedDuration: any) => {
  var x = {
    duration: selectedDuration
  }
  history.push(AppRoutes.PATIENTADHERENCE,x)
}
const handleVisibleChange = (flag: any) => {
  setVisible(flag);
};
const getFirstFilter = () => {
  switch (check[0]) {
    case Biometricname.BP:{
      return Biometricname.BP
    }
    case Biometricname.GLUCO:{
      return Biometricname.GLUCO
    }
    case Biometricname.PULSE:{
      return Biometricname.PULSE
    }
    case Biometricname.SPIRO:{
      return Biometricname.SPIRO
    }
    case Biometricname.TEMPRATURE:{
      return Biometricname.TEMPRATURE
    }
    case Biometricname.WEIGHT:{
      return Biometricname.WEIGHT
    }
  }
}
const applyFilters = () => {
  setFilters({
    ...filters,
      

  });
  setVisible(false);
};
const clearFilter = () => {
  if (check.length == 0) {
  setAllParams({
    all: false,
    [Biometricname.BP]: false,
    [Biometricname.GLUCO]: false,
    [Biometricname.PULSE]: false,
    [Biometricname.SPIRO]: false,
    [Biometricname.TEMPRATURE]: false,
    [Biometricname.WEIGHT]: false,
  });
  setFilters({});
  setVisible(false);
  setCheck([]);

 }
else{
  setVisible(false);
}};

const getAppliedFilter = () => {
  if (check.length == 0 || allParams?.all) {
    return "Biometric Type";
  } else {
    if (check.length <= 1) {
      return getFirstFilter()
    } else {
      return `${getFirstFilter()} & ${check.length - 1} more`;
    }
  }
};
const onConfirmCreate = async () => {
  if(editView){
    var z:any = {
      ...removeEmptyKeys(selectedSchedule),
      fromDate: moment(selectedSchedule?.fromDate).format('YYYY-MM-DD'),
      toDate: moment(selectedSchedule?.toDate).format('YYYY-MM-DD'),
      startTime: moment(selectedSchedule?.fromDate+" "+selectedSchedule?.startTime).format('HH:mm:ss'),
      endTime: moment(selectedSchedule?.fromDate+" "+selectedSchedule?.endTime).format('HH:mm:ss'),
      skipCheck: true,
    }
    dispatch(updatePatientSchedule(z))
  }else if(disableForm){
    dispatch(applyPresetSchedule(applyPresetList))
  }
  else{
    setNewScheduleList((newScheduleList: any) => [
      ...newScheduleList, 
      {...removeEmptyKeys(newSchedule), ["providerName"]: getFullName(appUser?.title,appUser?.firstName, appUser?.middleName, appUser?.lastName, )},
    ]);
    setUpdatedSchList((updatedSchList: any) => 
      [...updatedSchList, {...newSchedule,
        fromDate: moment(newSchedule?.fromDate, inputDateFormat).format('YYYY-MM-DD'),
        toDate: moment(newSchedule?.toDate,inputDateFormat).format('YYYY-MM-DD'),
       startTime: moment(newSchedule?.fromDate+" "+newSchedule?.startTime,`${inputDateFormat} HH:mm`).utc().format('HH:mm:ss'),
       endTime: moment(newSchedule?.toDate+" "+newSchedule?.endTime,`${inputDateFormat} HH:mm`).utc().format('HH:mm:ss'),
      }]
      )
}
  setShowConflict(false)
}

const getPresetOptions = () => {
  if(presetSchedules){
    return presetSchedules?.map((d:any)=>{
      return ( {
        text: d,
        value: d
      })
    })
  }else{return []}
}
useEffect(()=>{
  if(presetScheduleDetails?.length > 0){
    setNewScheduleList(presetScheduleDetails?.map((data: any)=>{
      return {
          biometricName: data?.biometricName,
          startTime: moment(data?.startTime, "HH:mm:ss").format("HH:mm"),
          endTime: moment(data?.endTime, "HH:mm:ss").format("HH:mm"),
          recurrence: data?.recurrence,
          instruction: data?.instruction,
          fromDate: moment(data?.fromDate,"YYYY-MM-DD").format(inputDateFormat),
          toDate: moment(data?.toDate,"YYYY-MM-DD").format(inputDateFormat),
          presetName: selectedPreset ,
          skipcount:data?.skipcount,
          ["providerName"]: getFullName(
            appUser?.title,
            appUser?.firstName,
            appUser?.middleName,
            appUser?.lastName
          ),
      }
    }))
  }
},[presetScheduleDetails])
  useEffect(()=>{
    setTemp(newScheduleList.map((schedule:any)=>{
        return {
          biometricName: schedule?.biometricName,
          assigneeId: appUser?.id,
          startTime: moment(schedule?.startTime, 'HH:mm').format("HH:mm:ss"),
          endTime: moment(schedule?.endTime, 'HH:mm').format("HH:mm:ss"),
          recurrence: schedule?.recurrence,
          instruction: schedule?.instruction,
          fromDate: moment(schedule?.fromDate, inputDateFormat ).format("YYYY-MM-DD"),
          toDate: moment(schedule?.toDate, inputDateFormat).format("YYYY-MM-DD"),
          patientId: selectedPatient?.id,
          isCustom: true,
          skipCheck: true,
          skipcount:schedule?.skipcount
        };
    }))
  },[newScheduleList])

  useEffect(()=>{
    if(isAssigned.isSuccess){
        message.success({content: "Schedule assigned successfully.", key: "appNotification",duration:5})
        setNewRow(false)
        setDisableForm(false)
        setSelectedPreset("")
        setApplyPresetList({})
        setNewScheduleList([])
        setUpdatedSchList([])
        setTableData([])  /*Cleaning table data before fetching schedules again */
        dispatch(fetchScheduleForPatient({patientId: selectedPatient?.id}))
        dispatch(clearState())
    }else if (isAssigned.isError){
      if(conflictedSch){
        setConflicted(conflictedSch)
        setTemp([])
        setShowConflict(true)
      }else {
        message.error({content:isAssigned.errorStack ? isAssigned.errorStack : "Something went wrong", duration:0 , key:"appNotification", onClick:onMessageClose, style:{cursor:"pointer"}})
    }
  dispatch(clearState())
  }
},[isAssigned.isSuccess, isAssigned.isError])

//recurrrence change detect
// document.addEventListener('mousemove', e => {
//   // console.clear()
//   let weeklyOpt =  document.elementFromPoint(e.clientX, e.clientY)
//   console.log(">>>>>>>>>>>", weeklyOpt )

//  if(weeklyOpt?.className == "ant-cascader-menu-item ant-cascader-menu-item-expand ant-cascader-menu-item-active" && weeklyOpt?.textContent?.includes("Weekly")){
//   setMultiple(true)
//  }else if(weeklyOpt?.className == "ant-cascader-menu-item-content" && weeklyOpt?.textContent?.includes("Daily")){
//   setMultiple(false)
//  }
// }, {passive: true})
  useEffect(()=>{
      if(isScheduleCreated.isSuccess){
          message.success({content:"Schedule Created Successfully.",duration:5,key:"appNotification"})
          setNewRow(false)
          setNewSchedule({skipcount:0})
          setNewScheduleList([])
          setTableData([])  /*Cleaning table data before fetching schedules again */
          setUpdatedSchList([])
          dispatch(fetchScheduleForPatient({patientId: selectedPatient?.id}))
          dispatch(clearState())
      }else if (isScheduleCreated.isError){
        if(conflictedSch){
          setConflicted(conflictedSch)
          setShowConflict(true)
        }else {
          message.error({content:isScheduleCreated.errorStack ? isScheduleCreated.errorStack : "Something went wrong", duration:0 , key:"appNotification", onClick:onMessageClose, style:{cursor:"pointer"}})
      }
    dispatch(clearState())
    }
  },[isScheduleCreated.isSuccess, isScheduleCreated.isError])

  useEffect(()=>{
    if(isScheduleUpdated.isSuccess){
        message.success({content:"Schedule Updated Successfully.",duration:5,key:"appNotification"})
        setEditView(false)
        dispatch(fetchScheduleForPatient({patientId: selectedPatient?.id}))
        dispatch(clearState())
    }else if (isScheduleUpdated.isError){
      if(conflictedSch){
        setConflicted(conflictedSch)
        setShowConflict(true)
      }else {
        message.error({content:isScheduleUpdated.errorStack ? isScheduleUpdated.errorStack : "Something went wrong", duration:0 , key:"appNotification", onClick:onMessageClose, style:{cursor:"pointer"}})
    } 
    dispatch(clearState())
  }
  dispatch(clearState())
},[isScheduleUpdated.isSuccess, isScheduleUpdated.isError])
useEffect(()=>{
  if(isScheduleDeleted.isSuccess){
      message.success({content:"Schedule Deleted Successfully.", key:"appNotification",duration:5})
      setAllParams({
        all: false,
        [Biometricname.BP]: false,
        [Biometricname.GLUCO]: false,
        [Biometricname.PULSE]: false,
        [Biometricname.SPIRO]: false,
        [Biometricname.TEMPRATURE]: false,
        [Biometricname.WEIGHT]: false,
      });
      setFilters({});
      setCheck([]);
      dispatch(fetchScheduleForPatient({patientId: selectedPatient?.id}))
  }else if (isScheduleDeleted.isError){
      message.error({content:isScheduleDeleted.errorStack ? isScheduleDeleted.errorStack : "Something went wrong",duration:0 , key:"appNotification", onClick:onMessageClose, style:{cursor:"pointer"}})
  }
dispatch(clearState())
},[isScheduleDeleted.isSuccess, isScheduleDeleted.isError])

  const onScheduleSave = async () => {
    if(disableForm){
      var x: boolean = false
      for await (const preSch of newScheduleList) {
        x = await isConflicted(preSch)
        if(!x){
          continue
        }else{
          x= true
          // setConflicted(preSch)
          setShowConflict(true)
          break
        }
      }
      if(!x){
        dispatch(applyPresetSchedule(applyPresetList))
      }
    }
    else if(!temp?.length){
          message.error({content:"Please add a schedule!",duration:0 , key:"appNotification", onClick:onMessageClose, style:{cursor:"pointer"}})
    }else{
        dispatch(createScheduleForPatient({schedules: [...temp]}))
    }
  }
  useEffect(()=>{
    var endhours = [];
    var startHours = []
    for(var i =0; i < moment(newSchedule?.startTime,'HH:mm').hour(); i++){
      endhours.push(i);
    }
    for(var j =24; j> moment(newSchedule?.endTime,'HH:mm').hour();j--){
      startHours.push(j)
    }
    setDisabledEndHours(endhours)
    setDisabledStartHours(startHours)

  },[newSchedule?.startTime, newSchedule?.endTime])
  const handlePresetSelect = (value:any) => {
    setSelectedPreset(value)
    setDisableForm(true)
    setApplyPresetList({
      scheduleName: value,
      patientId: selectedPatient?.id,
      assigneeId: appUser?.id,
    });
  }

  useEffect(()=>{
    if(selectedPreset){
      dispatch(fetchPresetScheduleDetail({scheduleName : selectedPreset}))
    }
  },[selectedPreset])
  
  useEffect(()=>{
    setTableData(scheduleTableData?.map((el:any)=>{
return ({...el, key :el.id})
    }))
  }, [scheduleTableData])

  const onCancelClick = () => {
    setNewScheduleList([]);
    setNewRow(false);
    setDisableForm(false);
    setTemp([]);
    setSelectedPreset("");
    setNewSchedule({skipcount:0})
    setTableData(scheduleTableData?.map((el:any)=>{
      return ({...el, key :el.id})
    }))
    setUpdatedSchList([]);
    // dispatch(clearPresetSchedule())
  }
  const providerOptions = (
    <Menu className="actionMenu">
      {
        providers?.map((pro:any)=>{
          return (
            <>
            <Menu.Item key={pro?.id} onClick={()=>{setSelectedProvider(pro); setCheck([]);setAllParams({
    all: false,
    [Biometricname.BP]: false,
    [Biometricname.GLUCO]: false,
    [Biometricname.PULSE]: false,
    [Biometricname.SPIRO]: false,
    [Biometricname.TEMPRATURE]: false,
    [Biometricname.WEIGHT]: false,
  })}}>{pro?.name}</Menu.Item>
            <Menu.Divider/>
            </>
          )
        })
      }
      <Menu.Item onClick={()=>{setSelectedProvider(null); setCheck([]);setAllParams({
    all: false,
    [Biometricname.BP]: false,
    [Biometricname.GLUCO]: false,
    [Biometricname.PULSE]: false,
    [Biometricname.SPIRO]: false,
    [Biometricname.TEMPRATURE]: false,
    [Biometricname.WEIGHT]: false,
  })}}>
        All
      </Menu.Item>
    </Menu>
  )
  const menu = (
    <Menu className="actionMenu ddlschedule">
      <Menu.Item key="0">
        <Checkbox
          checked={allParams.all}
          className="menu-itemCheckbox"
          name="all"
          onChange={handleAllCheck}
          value="Select All"
        >
          All
        </Checkbox>
      </Menu.Item>
      <Menu.Item key="1">
        <Checkbox
          checked={allParams[Biometricname.BP]}
          className="menu-itemCheckbox"
          name={Biometricname.BP}
          onChange={handleOtherItemClick}
        >
          Blood Pressure
        </Checkbox>
      </Menu.Item>
      <Menu.Item key="2">
        <Checkbox
          checked={allParams[Biometricname.GLUCO]}
          className="menu-itemCheckbox"
          name={Biometricname.GLUCO}
          onChange={handleOtherItemClick}
        >
          Glucose
        </Checkbox>
      </Menu.Item>
      <Menu.Item key="3">
        <Checkbox
          checked={allParams[Biometricname.WEIGHT]}
          className="menu-itemCheckbox"
          name={Biometricname.WEIGHT}
          onChange={handleOtherItemClick}
        >
          Weight
        </Checkbox>
      </Menu.Item>
      <Menu.Item key="4">
        <Checkbox
          checked={allParams[Biometricname.TEMPRATURE]}
          className="menu-itemCheckbox"
          name={Biometricname.TEMPRATURE}
          onChange={handleOtherItemClick}
        >
          Temperature
        </Checkbox>
      </Menu.Item>
      <Menu.Item key="5">
        <Checkbox
          checked={allParams[Biometricname.PULSE]}
          className="menu-itemCheckbox"
          name={Biometricname.PULSE}
          onChange={handleOtherItemClick}
        >
          Pulse Ox
        </Checkbox>
      </Menu.Item>
      <Menu.Item key="6">
        <Checkbox
          checked={allParams[Biometricname.SPIRO]}
          className="menu-itemCheckbox"
          name={Biometricname.SPIRO}
          onChange={handleOtherItemClick}
        >
          Spirometer
        </Checkbox>
      </Menu.Item>
      <Menu.Divider/>
      <Menu.Item key="buttons">
        <Row gutter={[20, 22]}>
        <Col span={24}>
            <Row>
              <Col offset={2} md={20} lg={22} xl={22} xxl={22}>
              <Row  gutter={[20, 22]}>
              <Col span={10} xxl={9} xl={10} lg={10} md={10}>
              <Button type="primary" className="okBtn" onClick={applyFilters}>
              <b>OK</b>
               </Button>
              </Col>
              <Col  xxl={15} xl={14} lg={14} md={14}>
              <Button type="primary" className="okBtn" onClick={clearFilter}>
              <b>CANCEL</b>
             </Button>
              </Col>
              </Row>
              </Col>
           
         
            </Row>
         
          </Col>
        </Row>
      </Menu.Item>
    </Menu>
  );
  
  return (
    <Spin spinning={isScheduleCreated.loading || isScheduleDeleted.loading || isScheduleUpdated.loading || isAssigned.loading}>
      <Card className="scheduleTable">
        <Row justify="space-between" className="patientShedule">
          <Col span={8} md={24} lg={8} xl={8}>
            <Row>
              <Col span={24} className="patientShedule">
              {editView ?
              (<span className="textFont f-16">Edit schedule </span>)
             
                : newRow ?
                (<span className="textFont f-16">Create schedule</span>)
               :(<span className="textFont f-16">Patient schedule ({scheduleTableData?.length})</span>)
            
            }
            
                
              </Col>
            </Row>
          </Col>
          <Col span={8} md={24} lg={10} xl={8}>
            <Row gutter={20} justify="end" className="colpatientShedule">
              {editView ? 
              <>
              <Col md={6} lg={12} xl={11} xxl={8}>
                <Button
                  type="primary"
                  className="colClass"
                  htmltype="submit"
                  form="scheduleUpdate"
                >
                  Save and Exit
                </Button>
              </Col>
              <Col md={6} lg={11} xl={10} xxl={8} style={{paddingRight:'0px'}}>
                <Button
                  type="primary"
                  className="colClass"
                  onClick={() =>{setEditView(false)}}
                >
                  Cancel
                </Button>
              </Col>
            </>:
              newRow ? (
                <>
                  <Col md={7} lg={11} xl={10} xxl={8}>
                    <Button
                      type="primary"
                      className="colClass"
                      onClick={onScheduleSave}
                    >
                      Save and Exit
                    </Button>
                  </Col>
                  <Col md={6} lg={11} xl={11}  xxl={8} style={{paddingRight:'0px'}}>
                    <Button
                      type="primary"
                      className="colClass"
                      onClick={onCancelClick}
                    >
                      Cancel
                    </Button>
                  </Col>
                </>
              ) : (
                <>
                  <Col md={7} lg={12} xl={12} xxl={11}>
                    <Row>
                      <Col md={24} span={20} lg={23} xl={24} xxl={20}>
                      <Button
                      type="primary"
                      className="colClass"
                      onClick={handleClick}
                    >
                      Create Schedule
                    </Button>
                      </Col>
                    </Row>
               
                  </Col>
                  
                
                  <Col xxl={2} xl={3} lg={4} md={2} onClick={()=>handleIconClick("day")} style={{textAlign:'center'}}>
                   <span className="material-icons-outlined schIcon">{CommonIcons.calendarToday}</span>
                    Today
                  </Col>
                  <Col xxl={2} xl={3} lg={4} md={2}  onClick={()=>handleIconClick("week")} style={{textAlign:'center'}}>
                  <span className="material-icons-outlined schIcon">{CommonIcons.calendarWeek}</span>
                    Week
                  </Col>
                  <Col xxl={2} xl={3} lg={4} md={2} onClick={()=>handleIconClick("month")} style={{textAlign:'center'}}>
                  <span className="material-icons-outlined schIcon">{CommonIcons.calendarMonth}</span>
                    Month
                  </Col>
                </>
              )}
            </Row>
          </Col>
        </Row>
        {editView? 
        <EditScheduleForm schedule={scheduleData} scheduleData={selectedSchedule} setScheduleData={setSelectedSchedule} scheduleList={scheduleTableData} setConflicted={setConflicted} setShowConflict={setShowConflict} getCountOption={getCountOption()}/> 
        :
        newRow ? (
          <>
            <Form layout="vertical" onFinish={handleAddSchedule} className="txtPatientSchedule">
           <Row gutter={20}>
            <Col  md={10} lg={7} xl={7} xxl={6}>
                  <SelectInput
                    labelSubName="Apply Preset Schedule"
                    placeholder="Select"
                    name="selectPresentSchedule"
                    className="selectInput"
                    bordered={true}
                    
                    onChange={handlePresetSelect}

                    optionValue={getPresetOptions()}
                  />
                </Col>
                </Row>
              <Row gutter={20} style={{ marginTop: "20px" }} >
                <Col  md={6} lg={5} xl={4} xxl={3}>
                  <SelectInput
                    labelSubName="Select Biometric Type"
                    placeholder="Select"
                    name="selectBiometricType"
                    className="selectInput"
                    bordered={true}
                    rules={[
                      {
                        required: true,
                        message: "Please select biometric type.",
                      },
                    ]}
                    optionValue={biometricTypeOptions}
                    onChange={handleBiometricSelect}
                    disabled={disableForm}
                  />
                </Col>
                <Col span={3} md={6} lg={3}>
                  <Row gutter={[0, 5]}>
                    <Col span={24}>
                      <Form.Item
                        name="fromDate"
                        label="Start Date"
                        rules={[
                          {
                            required: true,
                            message: "Please select Start sate",
                          },
                        ]}
                      >
                        <DatePicker
                          className="dobPicker"
                          onChange={onStartDatePick}
                          allowClear = {false}
                          placeholder={inputDateFormat}
                          disabledDate={(date)=>date.isBefore(moment().subtract(1,"day"))}
                          format={inputDateFormat}
                          disabled={disableForm}
                          value={newSchedule?.fromDate}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={3} md={6}  lg={3}>
                  <Row gutter={[0, 5]}>
                    <Col span={24}>
                      <Form.Item
                        name="toDate"
                        label="End Date"
                        rules={[
                          { required: true, message: "Please select End Date" },
                        ]}
                      >
                        <DatePicker
                          className="dobPicker"
                          onChange={onEndDatePick}
                          allowClear={false}
                          disabledDate={(date)=>date.isBefore(moment(newSchedule?.fromDate, inputDateFormat))}
                          size="small"
                          placeholder={inputDateFormat}
                          format={inputDateFormat}
                          disabled={disableForm}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={3} md={6} lg={3} xl={3} xxl={2}>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        name="startTime"
                        label="Start Time"
                        rules={[
                          {
                            required: true,
                            message: "Please select Start time",
                          },
                        ]}
                      >
                        <TimePicker
                          disabledHours={()=>disabledStartHours}
                          onChange={handleStartTime}
                          format={"HH:mm"}
                          use12Hours
                          size="large"
                          disabled={disableForm}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={3} md={6} lg={3} xl={3} xxl={2}>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        name="endTime"
                        label="End Time"
                        rules={[
                          { required: true, message: "Please select End time" },
                        ]}
                      >
                        <TimePicker
                          disabledHours={()=>disabledEndHours}
                          onChange={handleEndTime}
                          format={"HH:mm"}
                          use12Hours
                          size="large"
                          disabled={disableForm}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={3} md={6} lg={3} xl={4} xxl={3}>
                  <Form.Item
                    name="recurrence"
                    label="Recurrence"
                    className="txtRecurrence"
                    rules={[
                      { required: true, message: "Please select recurrence" },
                    ]}
                  >
                    <Cascader
                      className="custom"
                      style={{ width: "100%" }}
                      options={recurrenceOptions}
                      dropdownMatchSelectWidth
                      // onClick={detectClickonRec}
                      expandTrigger={"hover"}
                      
                      onChange={handleReccurrenceSelect}
                      multiple={multiple}
                      value={getRecurrenceValue()}
                      changeOnSelect
                      maxTagCount="responsive"
                      disabled={disableForm}
                    />
                  </Form.Item>
                </Col>
                <Col span={3} md={6} lg={4} xl={4} xxl={3}>
                  <SelectInput
                    labelSubName="Select Instructions"
                    placeholder="Instructions"
                    name="instructions"
                    className="selectInput"
                    bordered={true}
                    initialValue=""
                    optionValue={instructionOptions}
                    onChange={handleInstruction}
                    disabled={disableForm}
                    rules={[
                      { required: instructionReqd, message: "Please select instructions" },
                    ]}
                  />
                </Col>
                <Col span={3} md={6} lg={5} xl={4} xxl={3} style={{marginTop:'-27px'}}>
                  
                <CustomTooltip placement="top" heading="Note: " title={Messages.SKIP_COUNT_MESSAGE} color="#ffffff" overlayClassName="infoToolTip" > 
                <div className="infoIconDiv"><span className="material-icons-outlined infoIcon">{CommonIcons.info}</span></div>
                </CustomTooltip>
             
               <SelectInput
                    name="skipCount"
                    labelSubName="Non-Adherance Count"
                    placeholder="skip count"
                    className="selectInput"
                    bordered={true}
                    initialValue={'0'}
                    disabled={disableForm}
                    onChange={handleSkipCount}
                    optionValue={getCountOption()}
                    
                  />
                </Col>
                <Col span={2} className="schbttun">
                  <Button
                    type="primary"
                    className="addButton schbttun"
                    htmltype="submit"
                    disabled={disableForm}
                  >
                    +ADD
                  </Button>
                </Col>
              </Row>


            </Form>
            <Row className="tableSpecial sheduleTable MobViewTable">
              <Col span={24}>
                <PatientScheduleTable
                tbId={"new"}
                  tableData={newScheduleList}
                  preSaved={true}
                  handleRemoveSchedule={handleRemoveSchedule}
                />
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row gutter={20}>
              <Col md={6} lg={5} xl={4} xxl={3}>
              <Dropdown
                  className="cdfg"
                  overlayClassName="actionMenu"
                  overlay={providerOptions}
                  trigger={["click"]}
                  getPopupContainer={(trigger:any)=>trigger.parentNode}
                >
                <Button className="buttonSchedule f-14">
                  <img src={filter} />
                  <span className="textFont f-12 buttonText">{selectedProvider? selectedProvider?.name: "PROVIDER"}</span>
                  <img src={up} className="" />
                </Button>
        
                </Dropdown>
              </Col>
              <Col  md={6} lg={5} xl={4} xxl={3}>
                <Dropdown
                  className="cdfg"
                  overlayClassName="actionMenu"
                  overlay={menu}
                  trigger={["click"]}
                  onVisibleChange={handleVisibleChange}
                  visible={visibleMenuSettings}
                  getPopupContainer={(trigger:any)=>trigger.parentNode}
                >
                  <button className="buttonSchedule f-14">
                    <img src={filter} className="" style={{marginRight:'5px'}} />
                    <span className="textFont f-12 buttonText criticalBtn slice">
                    {getAppliedFilter()}
                    </span>
                    <img src={up} className="" />
                  </button>
                </Dropdown>
              </Col>
            </Row>
              <Row className="tableSpecial">
                <Col span={24}>
                  <PatientScheduleTable tbId={"saved"} tableData={tableData} onEditClick={onEditClick} deleteSchedule={deleteSchedule}/>
                </Col>
              </Row>
          </>
        )}
      </Card>
      <ConflictModal type={ModalType.WARN} isModalVisible={showConflict} cancelCallback={()=>setShowConflict(false)} confirmCallback={onConfirmCreate} cancelButton={ModalCallBackTypes.CANCEL} confirmButton={ModalCallBackTypes.CONTINUE} primaryText={ModalPrimaryMessages.SCH_CONFLICT} providerName={getFullName(appUser?.title, appUser?.firstName, appUser?.middleName, appUser?.lastName)} biometricType={conflicted?.biometricName} startTime={conflicted?.startTime} endTime={conflicted?.endTime}/ >
    </Spin>
  );
};

import { Table } from "antd";
import moment from "moment";
import { useSelector } from "react-redux";
import { AuthStateSelector } from "../../../../../redux/reducers/authReducer/authReducer";
import { biometricNamesObject } from "../../../../constants/constants";
import { Biometricname } from "../../../../constants/enums";
import { AddEditRenderer } from "./addEditRenderer";

export const PatientScheduleTable = (props: any) => {
  const { tableData, preSaved, handleRemoveSchedule, onEditClick, deleteSchedule,tbId } = props;
  const {appUser} = useSelector(AuthStateSelector)
  const column = [
    {
      title: "Biometric Type",
      dataIndex: "biometricName",
      className: "instructionTable",
      key: "biometricType",
      width: "10%",
      render : (bioName:Biometricname)=>biometricNamesObject[bioName]
    },
    {
      title: "Provider Name",
      dataIndex: "providerName",
      className: "instructionTable",
      key: "providerName",
      width: "10%",
    },
    {
      title: "Start Time",
      dataIndex: "startTime",
      className: "instructionTable",
      key: "startTime",
      width: "10%",
      render: (time:any)=>preSaved? moment(time, 'HH:mm').format("HH:mm a"): moment.utc(time, 'HH:mm').local().format("HH:mm a")
    },
    {
      title: "End Time",
      dataIndex: "endTime",
      className: "instructionTable",
      key: "endTime",
      width: "10%",
      render: (time:any)=>preSaved? moment(time, 'HH:mm').format("HH:mm a"): moment.utc(time, 'HH:mm').local().format("HH:mm a")
    },
    {
      title: "Recurrence",
      dataIndex: "recurrence",
      className: "instructionTable",
      key: "recurrence",
      width: "10%",
      render: (rec: any)=> {return rec?.constructor  === Array ? rec.join(): rec }
    },
    {
      title: "Instructions",
      dataIndex: "instruction",
      className: "instructionTable",
      key: "instruction",
      width: "10%",
    },
    {
      title: "Start Date",
      dataIndex: "fromDate",
      className: "instructionTable",
      key: "startDate",
      width: "10%",
      render: (date:any, row:any)=>(preSaved==true)?moment(date, "MM-DD-YYYY").format("DD MMM YYYY"):moment.utc(date+" "+row?.startTime).local().format("DD MMM YYYY")
    },
    {
      title: "End Date",
      dataIndex: "toDate",
      className: "instructionTable",
      key: "endDate",
      width: "10%",
      render: (date:any,row:any)=>(preSaved==true)?moment(date, "MM-DD-YYYY").format("DD MMM YYYY"):moment.utc(date+" "+row?.endTime).local().format("DD MMM YYYY")
    },
    {
      title: 'Non-Adherance Count',
      dataIndex:'skipcount',
      className:'instructionTable',
      key:'skipCount',
      width:'15%'
    },
    {
      title: "Action",
      dataIndex: "action",
      className: "deleteIcon",
      key: "delete",
      width: "7%",
      render: (schedule: any, row: any) => {
        return  <AddEditRenderer value={schedule} row={row} onEditClick={onEditClick} preSaved={preSaved} onDeleteClick={handleRemoveSchedule} deleteSchedule={deleteSchedule}/>;
      },
    },
  ];
  return (
    <Table
    id={tbId}
    scroll={{y:"450px",x:"900px"}}
      className="centreTable PatientSheduleMbView"
      dataSource={tableData}
      columns={column}
      tableLayout="fixed"
      pagination={false}
    />
  );
};

import { Card, Col, Divider, Row } from "antd"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { fetchPatientOrg } from "../../../../../redux/actions/patient/patientAction"
import { PatientStateSelector } from "../../../../../redux/reducers/patient/patientReducer"
import { concatNames } from "../../../../../utility/appUtil"
import { contactNoFormat } from "../../../../../utility/utils"
import { call, mail,mobileIcon} from "../../../../images"
import Button from "../../common/button"
import { CompWrapper } from "../../common/contentWrapper"
import ProfileIcon from "../../common/profileThumbnail"
import CustomTooltip from "../../common/toolTip"
import "./patientOrganization.less"

export const PatientOrganization = (props : any) => {
    const {patientOrgDetails,cancelCallback, setSelectedTab} = props;
    const {patientOrg, selectedPatient} = useSelector(PatientStateSelector)
    const dispatch = useDispatch()

    useEffect(()=>{
        if(selectedPatient && selectedPatient?.id){
            dispatch(fetchPatientOrg(selectedPatient?.orgId))
        }
    },[])
    return (
        <CompWrapper observeOn="mainRow" name="patientOrgCard">

            <Card className="patientOrgCard hSOPatientDetails">
                <Row gutter={58}>
                    <Col md={6} lg={4} xl={4} xxl={4}>
                    <div className="patientsprofile" style={{textAlign:'center'}}>
                    <ProfileIcon className="orgIcon" name={patientOrg?.orgName} size="99" />
                    </div>
                    </Col>
                    <Col md={18} lg={20} xl={20}>
                    <Row>
                    <Col md={19} lg={21} xl={21}>
                        <p className="patientOrgName f-20">{patientOrg?.orgName}</p>
                        <p className="patientOrgAddres text" style={{textTransform: 'capitalize'}}>{patientOrg?.address1}, {patientOrg?.city}, {patientOrg?.state} {patientOrg?.zipcode}, {patientOrg?.country}</p>
                    </Col>
                  
                    <Col md={19} lg={21} xl={21}>
                        <Row className="detailPatientOrgform">
                            <Col md={24} lg={11} xl={11} xxl={10}>
                                <p className="patientOrg prime">Primary Contact Details </p>
                                {patientOrg?.primaryContact ?
                                <>
                                <div className="patientOrg info f-14 emailPaientDetails"> 
                                  <CustomTooltip  title={concatNames(patientOrg?.primaryContact?.firstName, patientOrg?.primaryContact?.lastName, patientOrg?.primaryContact?.middleName)}
                                    color="#FFFFFF" content="show" placement="right">
                                    <p className="customPara slice">{concatNames(patientOrg?.primaryContact?.firstName, patientOrg?.primaryContact?.lastName, patientOrg?.primaryContact?.middleName)}</p>  
                                </CustomTooltip>        
                                </div>
                               <div style={{marginBottom:'10px'}} className="mobileSection"> 

                               {patientOrg?.primaryContact?.mobile ?
                                 <div className='primeOrgDetails'><img className='emailI' src={call} />{contactNoFormat(patientOrg?.primaryContact?.mobile)}</div>
                                 :null
                               }

                               {patientOrg?.primaryContact?.phoneNumber ?
                                <div className="primeOrgDetails"><img className="emailI " src={mobileIcon}/>{contactNoFormat(patientOrg?.primaryContact?.phoneNumber)}</div>
                                :null
                               }
                                
                                </div>
                                {patientOrg?.primaryContact?.email ?
                                <div className='primeOrgDetails emailPaientDetails'style={{marginTop:'5px'}}>
                                 <CustomTooltip title={patientOrg?.primaryContact?.email} color="#FFFFFF" content="show" placement="right">
                                     <p className="customPara slice"><img className='emailI' src={mail} />{patientOrg?.primaryContact?.email}
                                     </p>
                                </CustomTooltip>
                                </div>
                            
                            : null  
                            }
                                
                                </>
                                : null}
                            </Col>
                            <Col className="dividerCol" md={0} lg={2} xl={1} xxl={4}>                            
                            <Divider className="divider" type="vertical"/>
                            </Col>
                            <Col md={24} lg={11} xl={12} xxl={10}>
                                <p className="patientOrg sectionSecondary prime">Support Contact Details </p>
                                {patientOrg?.secondaryContact ?
                                <>
                                  <div className="patientOrg info f-14 emailPaientDetails"> 
                                  <CustomTooltip  title={concatNames(patientOrg?.secondaryContact?.firstName, patientOrg?.secondaryContact?.lastName, patientOrg?.secondaryContact?.middleName)}
                                    color="#FFFFFF" content="show" placement="right">
                                    <p className="customPara slice">{concatNames(patientOrg?.secondaryContact?.firstName, patientOrg?.secondaryContact?.lastName, patientOrg?.secondaryContact?.middleName)}</p>
                                </CustomTooltip>        
                                </div>
                               <div style={{marginBottom:'10px'}} className="mobileSection">

                                   {patientOrg?.secondaryContact?.mobile ? 
                                   <div className='primeOrgDetails'><img className='emailI' src={call} />{contactNoFormat(patientOrg?.secondaryContact?.mobile)}</div>
                                   :null 
                                }
                               
                               {patientOrg?.secondaryContact?.phoneNumber ?
                               <div className="primeOrgDetails"><img className="emailI " src={mobileIcon}/>{contactNoFormat(patientOrg?.secondaryContact?.phoneNumber)}</div>
                               : null
                            }
                                
                                </div>
                                {patientOrg?.secondaryContact?.email ?
                                <div className='primeOrgDetails emailPaientDetails' style={{marginTop:'5px'}}>
                                 <CustomTooltip title={patientOrg?.secondaryContact?.email} color="#FFFFFF" content="show" placement="right">
                                     <p className="customPara slice"><img className='emailI' src={mail} />{patientOrg?.secondaryContact?.email}
                                     </p>
                                </CustomTooltip>
 
                                </div>
                                :null
                                }
                               


                                </>
                                : null}
                            </Col>
                        </Row>
                    </Col>
      </Row>
        </Col>
                    
                </Row>
                {setSelectedTab ? 
            <Row className="btnpateintfooter" justify="end" gutter={20}>
                <Col span={4}>
                <Button type="primary" onClick={()=>setSelectedTab("6")}>Next</Button>
                </Col>
                <Col span={4}>
                <Button type="primary" onClick={()=>cancelCallback()}>Cancel</Button>
                </Col>
            </Row>
            :
            null}
            </Card>
      
</CompWrapper>
    )
}
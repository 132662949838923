export enum APIEnvironment {
  DEV = "DEV",
  QA = "QA",
  PROD = "PROD",
}

export enum PortalModule {
  ORGANIZATION = "organisation",
  DEPARTMENT = "department",
  LOGIN = "login",
  CENTER = "center",
  USER ="user",
  ADDUSER="users",
  ROLE="role",
  PERMISSION="permission",
  PROVIDER="provider",
  PROVIDERTYPES = "providerType",
  SPECIALITY = "specialty",
  HRM = "hrm",
  RESETPASSWORD = "resetPassword",
  REQUESTCHANGEPASSWORD = "requestChangePassword",
  CHANGEPASSWORD = "changePassword",
  PATIENT = "patient",
  AUDIT = "audit",
  FAMILY = "family",
  PROVIDERPATIENT = "provider/patient",
  PATIENTPROVIDER = "patient/provider",
  RELATIONSHIP = "relationship",
  PATIENTREVIEWS = "patientReviews",
  PAYER= "payer",
  HUB= "hub",
  ROLES = "roles",
  DEVICE = "device",
  LEVEL = "level",
  IMAGE = "image",
  NOTIFICATION = "notification",
  WEB_SOCKET="socket",
  ALERT = "alert",
  ASSIGN = "assign",
  UNASSIGN = "unassign",
  REPORTS = "report"
}

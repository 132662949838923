export const bpDummyData =[
    {
        
        lowSystolic : "57",
        highSystolic : "110",
        lowDiastolic: "30",
        highDiastolic: "140",
        lowPulse : "75",
        highPulse : "120",
        glucoHigh:"70",
        glucoLow: "40",
        oxPulseHigh: "200",
        oxPulseLow: "70",
        weightLow:"50",
        weightHigh:"200",
        tempHigh:"70",
        tempLow:"105",
        weightFormat:"kgs",
        tempFormat: "C",
        intraDay: "40%",
        twoDays: "20%",
        fiveDays: "25%",
        fourteenDays :"35%",
        thirtyDays: "45%",
        adherence: "55%", 
        
    },
    {  
        lowSystolic : "57",
        highSystolic : "110",
        lowDiastolic: "30",
        highDiastolic: "140",
        lowPulse : "75",
        highPulse : "120",
        glucoHigh:"70",
        glucoLow: "40",
        oxPulseHigh: "200",
        oxPulseLow: "70",
        weightLow:"50",
        weightHigh:"200",
        tempHigh:"70",
        tempLow:"105",
        weightFormat:"kgs",
        tempFormat: "C",
        intraDay: "40%",
        twoDays: "20%",
        fiveDays: "25%",
        fourteenDays :"35%",
        thirtyDays: "45%",
        adherence: "55%", 
    
},
{
        
        lowSystolic : "57",
        highSystolic : "110",
        lowDiastolic: "30",
        highDiastolic: "140",
        lowPulse : "75",
        highPulse : "120",
        glucoHigh:"70",
        glucoLow: "40",
        oxPulseHigh: "200",
        oxPulseLow: "70",
        weightLow:"50",
        weightHigh:"200",
        tempHigh:"70",
        tempLow:"105",
        weightFormat:"kgs",
        tempFormat: "C",
        intraDay: "40%",
        twoDays: "20%",
        fiveDays: "25%",
        fourteenDays :"35%",
        thirtyDays: "45%",
        adherence: "55%",  
},
]
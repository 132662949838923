import { Card, Checkbox, Col, Form, message, Row, Upload } from "antd";
import { camera, circle } from "../../../../../images";
import InputBox from "../../../common/inputBox";
import ProfileIcon from "../../../common/profileThumbnail";
import { PlusOutlined } from "@ant-design/icons";
// @ts-ignore
import { Country, State, City } from 'country-state-city';
import "./addFamily.less"
import SelectInput from "../../../common/selectInput";
import { userStatusOptions, titleOptions } from "../../../../../constants/constants";
import { PhoneInput } from "../../../common/phoneInput";
import Button from "../../../common/button";
import { UserService } from "../../../../../services/user/userServices";
import { getCountriesList } from "../../../../../../utility/appUtil";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { UserStateSelector } from "../../../../../../redux/reducers/user/userReducer";
import { RegExpressions } from "../../../../../constants/regexp";
import { onMessageClose } from "../../../../../../utility/utils";



export const FamilyForm = (props: any) => {
    const userSrv = new UserService()
    const [isChecked, setChecked] = useState(false)
    const [chkdisabled, setDisable] = useState(true)
    const [cities, setCities] = useState<any>([])
    const [states, setStates] = useState<any>([])
    const { formState, selectedUser, isCreated } = useSelector(UserStateSelector)
    const { data, setData, setSelectedTab, onSubmit, relationOptions, roleOptions, showFamilyForm, disableStatus, setDisableSave,
        disableSave, familyFormNextDisabled,onGeneratePassCheck, getRelationOptions } = props;

    const selectValue = [
        {
            text: "A",
            value: "a",
        },
        {
            text: "B",
            value: "b",
        },
    ];
    function handleChange(Event: any) {
        setData({
            ...data,
            [Event.target.name]: Event.target.value,
        });
        if (Event.target.name == "email") {
            setDisable(false)
        }
    }


    const onFamilyFormClick = () => {
        onSubmit()
    }



    const relationSelect = (value: any) => {
        setData({
            ...data,
            ["relationshipId"]: value,

        });
    };
    const roleStatus = (value: any) => {
        setData({
            ...data,
            ["roleId"]: value,
        });
    };
    const StatusChange = (value: any) => {
        setData({
            ...data,
            ["status"]: value,

        });
    };
    const onTitleSelect = (value: any) => {
        setData({
            ...data,
            ["title"]: value,

        });
    }
    const handleUpload = async ({ fileList }: any) => {
        var form = new FormData();
        form.append("avatar", fileList?.[0].originFileObj);
        if (form) {
            userSrv.uploadImage(form).then(x => 
                setData({
                ...data, ["imageUrl"]: x,
            })
            ).catch(e => message.error({content:"Failed to upload image.",duration:0 , key:"appNotification", onClick:onMessageClose, style:{cursor:"pointer"}}))
        }
    };


    const handleBeforeUpload = async (file: any, fileList: any) => {
        const isJpgOrPng =
            file.type === "image/jpeg" ||
            file.type === "image/png" ||
            file.type === "image/jpg";
        if (!isJpgOrPng) {
            message.error({content:"You can only upload JPG/PNG file!",duration:0 , key:"appNotification", onClick:onMessageClose, style:{cursor:"pointer"}});
        }
        const isLt2M = file.size / 1024 / 1024 < 0.25;
        if (!isLt2M) {
            message.error({content:"Image must smaller than 250K!",duration:0 , key:"appNotification", onClick:onMessageClose, style:{cursor:"pointer"}});
        }
        const isValid = await checkImageWH(file, 640, 640);
        return isJpgOrPng && isLt2M && isValid ? false : Upload.LIST_IGNORE;
    };

    const checkImageWH = (file: any, width: number, height: number) => {
        return new Promise<boolean>(function (resolve, reject) {
            let filereader = new FileReader();
            filereader.onload = (e: any) => {
                let src = e.target.result;
                const image: any = new Image();
                image.onload = function () {
                    if (
                        (this.width && this.width > width) ||
                        (this.height && this.height > height)
                    ) {
                        message.error({
                            content:"Please upload picture of size less than or equal to " +
                            width +
                            " * " +
                            height
                        ,duration:0 , key:"appNotification", onClick:onMessageClose, style:{cursor:"pointer"}});
                        return false;
                    } else {
                        resolve(true);
                    }
                };
                image.onerror = reject;
                image.src = src;
            };
            filereader.readAsDataURL(file);
        });
    };

    function onChecked(e: any) {
        const { checked } = e.target;
        setChecked(checked)
        if (checked) {
            setData({ ...data, ["username"]: data["email"] });
        } else {
            setData({ ...data, ["username"]: "" });
        }

    }
    const handleCountrySelect = (value: any) => {
        setData({
            ...data,
            ["country"]: value,
        });
    }
    const detectChange = () => {
        if (setDisableSave) {
            setDisableSave(false)
        }
    }
    const handleCountrySelector = (value: any) => {
        setData({ ...data, ["country"]: value, ["timezone"]: null , ["city"]: null, ["state"]: null })
        
        setStates(
            State.getStatesOfCountry(value).map((state: any) => {
                return (
                    {
                        text: state.name,
                        value: state.isoCode
                    }
                )
            }
            ))
          
            setCities([])
      }
    useEffect(()=>{
        setStates(
            State.getStatesOfCountry(data?.country).map((state: any) => {
                return (
                    {
                        text: state.name,
                        value: state.isoCode
                    }
                )
            }
            ))
    },[data?.country])
    useEffect(()=>{
        var stateCode:string = State.getStatesOfCountry(data?.country)?.find((code:any)=>code.name==data?.state)?.isoCode || ""
        setCities(
            City.getCitiesOfState(data?.country, stateCode ).map((city: any) => {
                return (
                    {
                        text: city.name,
                        value: city.name
                    }
                )
            }
            ))
    },[data?.state])
    
      const handleStateSelect = (value: any) => {
        var stateByCode = State.getStateByCodeAndCountry(value,data?.country)?.name
        setData({ ...data, ["state"]: stateByCode, ["city"]: "" })
        setCities(City.getCitiesOfState(data.country, value).map((city: any) => {
            return (
                {
                    text: city.name,
                    value: city.name
                }
            )
        }
        ))
    }
    const handleCitySelect = (value: any) => {
        setData({ ...data, ["city"]: value })
      }

      var focusedInput=useRef<any>(null)
useEffect(()=>{
focusedInput.current!.focus()
},[])

    return (
        <Card className="addFamily">
            <Form key={`${isChecked} ${states.length + cities.length}`} layout="vertical" id="addFamily" onFinish={onFamilyFormClick}>
                <Row gutter={[50, 50]}>
                   
                    <Col span={24}>
                        <Row gutter={[20, 25]}> 
                            <Col className="imgPatient" span={7} style={{textAlign:"center"}}>
                                <Form.Item name="imageUrl" initialValue={data?.imageUrl} className="addAdminItem">
                                    <Upload
                                        beforeUpload={handleBeforeUpload}
                                        maxCount={1}
                                        openFileDialogOnClick={true}
                                        onChange={handleUpload}
                                        showUploadList={false}
                                    >
                                        {data.imageUrl ? (
                                            <div className="uploadContainer f-10">
                                                <ProfileIcon src={data?.imageUrl} size="100" />
                                               
                                                <div className="addFamilyCamera">
                                                    <img src={circle} className="circleImg" ></img>
                                                    <img src={camera} className="cameraImg"></img>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="uploadContainer f-10">
                                                <div className="orgInfoLogo addAdmiLog">
                                                    <PlusOutlined />
                                                    <p>Profile Picture</p>
                                                    <div  className="addPatientUpload"> 
                                                    <img src={circle} className="circleImg"></img>
                                                    <img src={camera} className="cameraImg"></img>
                                                </div>
                                                </div>
                                               
                                               
                                            </div>
                                        )}
                                    </Upload>
                                    <span className="uploadTexts">
                          Upload Image JPG, PNG, Max Size 250K Max Resolution 640px X
                          640px,
                        </span>
                                </Form.Item>
                            </Col>
                            <Col span={17}>
                                <Row gutter={[20,25]}>
                                    <Col md={12} lg={3} xl={3} >
                                        <SelectInput
                                            labelSubName="Title"
                                            name="title"
                                            initialValue={data?.title}
                                            value={data?.title}
                                            rules={[
                                                {
                                                    required: false,
                                                    message: "Please enter",
                                                },
                                            ]}
                                            optionValue={titleOptions}
                                            onChange={onTitleSelect}
                                            refer={focusedInput}
                                        />
                                    </Col>
                                    <Col md={12} lg={9} xl={9} >
                                        <InputBox
                                            labelSubName="First Name"
                                            name="firstName"
                                            initialValue={data?.firstName}
                                            value={data?.firstName}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please enter first name",
                                                },
                                                {
                                                    pattern: RegExpressions.FirstName,
                                                    message: "Please enter valid name"
                                                }
                                            ]}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                    <Col  md={12} lg={3} xl={3}  >
                                        <InputBox
                                            labelSubName="MI"
                                            name="middleName"
                                            initialValue={data?.middleName}
                                            value={data?.middleName}
                                            rules={[{
                                                pattern: RegExpressions.Middlename,
                                                message: "Invalid MI"
                                            }]}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                    <Col  md={12} lg={9} xl={9} >
                                        <InputBox
                                            labelSubName="Last Name"
                                            name="lastName"
                                            initialValue={data?.lastName}
                                            value={data?.lastName}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please enter last name",
                                                },
                                                {
                                                    pattern: RegExpressions.LastName,
                                                    message: "Please enter valid name"
                                                }
                                            ]}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                    <Col span={24}>
                                        <InputBox
                                            labelSubName="Address 1"
                                            name="address1"
                                            initialValue={data?.address1}
                                            value={data?.address1}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please enter address",
                                                },
                                            ]}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row gutter={[20,0]}>
                            <Col span={7} className="familyAddDtl">
                                <SelectInput
                                    labelSubName="Relation"
                                    placeholder="select"
                                    name="relation"
                                    className="card-dropdown with-search"
                                    bordered={true}
                                    initialValue={data?.relationshipId}
                                    value={data?.relationshipId}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please enter relation",
                                        },
                                    ]}
                                    optionValue={relationOptions}
                                    onChange={relationSelect}
                                />
                            </Col>
                            <Col span={17}  className="familyAddDtl">
                                <InputBox
                                    labelSubName="Address 2"
                                    name="address2"
                                    initialValue={data?.address2}
                                    value={data?.address2}
                                    rules={[
                                        {
                                            required: false,
                                            message: "Please enter ",
                                        },
                                    ]}
                                    onChange={handleChange}
                                />
                            </Col>
                        </Row>
                        <Row gutter={[20, 25]}>
                            <Col span={7}  className="familyAddDtl">
                                <SelectInput
                                    disabled={disableStatus}
                                    labelSubName="Status"
                                    placeholder="select"
                                    name="status"
                                    className="card-dropdown with-search"
                                    bordered={true}
                                    initialValue={disableStatus ? 'Invited' : data.status}
                                    optionValue={userStatusOptions}
                                    onChange={StatusChange}
                                />
                            </Col>
                            <Col span={17}>
                                <Row gutter={[20,0]}>
                                <Col span={6} md={12} lg={6} xl={6}  className="familyAddDtl">
                                        <SelectInput
                                            labelSubName="Country"
                                            placeholder="Select country"
                                            name="country"
                                            initialValue={data?.country}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please select Country",
                                                },
                                            ]}
                                            className="card-dropdown with-search"
                                            bordered={true}
                                            value={data?.country}
                                            optionValue={getCountriesList()}
                                            onChange={handleCountrySelector}
                                            showSearch
                                        />
                                    </Col>
                                <Col span={6} md={12} lg={6} xl={6}  className="familyAddDtl">
                                    {states.length <= 0 ?
                                        <InputBox
                                            labelSubName="State/Province"
                                            name="state"
                                            initialValue={data?.state}
                                            value={data?.state}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please enter state/province",
                                                }
                                            ]}
                                            onChange={handleChange}
                                        />
                                        :
                                        <SelectInput
                                            labelSubName="State/Province"
                                            name="state"
                                            initialValue={data?.state}
                                            value={data?.state}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please enter state/province",
                                                }
                                            ]}
                                            showSearch
                                            optionValue={states}
                                            onChange={handleStateSelect}
                                        />}
                                    </Col>
                                    <Col span={6} md={12} lg={6} xl={6}  className="familyAddDtl">
                                        {cities.length <= 0 ?
                                        <InputBox
                                            labelSubName="City"
                                            name="city"
                                            initialValue={data?.city}
                                            value={data?.city}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please enter the city",
                                                }
                                            ]}
                                            onChange={handleChange}
                                        />
                                        : 
                                        <SelectInput
                                        labelSubName="City"
                                        name="city"
                                        initialValue={data?.city}
                                        value={data?.city}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please enter the city",
                                            }
                                        ]}
                                        showSearch
                                        optionValue={cities}
                                        onChange={handleCitySelect}
                                    />}
                                    </Col>
                                   
                                    <Col md={12} lg={6} xl={6}  className="familyAddDtl">
                                        <InputBox
                                            labelSubName="Zip/Postal Code"
                                            name="zipCode"
                                            initialValue={data?.zipCode}
                                            value={data?.zipCode}
                                            placeholder="eg. 00000-0000"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please enter Zip/Postal code",
                                                },
                                                {
                                                    pattern: RegExpressions.ZipCode,
                                                    message: "Please enter valid Zip/Postal code",
                                                }
                                            ]}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                   
                                </Row>
                            </Col>
                        </Row>
                        <Row gutter={[20, 25]}>
                            <Col span={7}></Col>
                            <Col span={17}>
                                <Row gutter={[20, 25]}>
                                <Col  span={12}>
                                    <InputBox
                                        labelSubName="Email Address"
                                        name="email"
                                        initialValue={data?.email}
                                        value={data?.email}
                                        rules={[
                                            {
                                                required: false,
                                                message: "Please enter Email Address",
                                            },
                                            {
                                                pattern: RegExpressions.Email,
                                                message: "Please enter valid Email Address"
                                            }
                                        ]}
                                        onChange={handleChange}
                                    />
                                </Col>
                                <Col md={12} lg={12} xl={12} className="patientUserName">
                                    <Checkbox onChange={onChecked} defaultChecked={data?.username && data?.email == data?.username} disabled={chkdisabled} className="checkboxEmail">
                                        Use Email Address
                                    </Checkbox>
                                    <Checkbox className="provCheckboxemail"  onChange={onGeneratePassCheck}
                >Generate Password</Checkbox>
                                    <InputBox
                                        key = {`${isChecked}`}
                                        labelSubName="User Name"
                                        name="username"
                                        placeholder="minimum 6 characters"
                                        initialValue={data?.username}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please enter username",
                                            },
                                            {
                                                pattern: RegExpressions.UserName,
                                                message: "Username can not contain space and must be 6 or more characters"
                                              }
                                        ]}
                                        value={data?.username}
                                        onChange={handleChange}
                                    />


                                </Col>
                                <Col span={12}>
                                    <PhoneInput
                                        name="contactNumber"
                                        label="Contact"
                                        initialValue={data?.contact}
                                        value={data?.contact}
                                        obj={data}
                                        setObj={setData}
                                        detectChange={detectChange}
                                    />
                                </Col>
                                <Col span={12}>
                                    <PhoneInput
                                        name="mobileNumber"
                                        label="Mobile Number"
                                        initialValue={data?.mobileNumber}
                                        value={data?.mobileNumber}
                                        rules={
                                            {
                                                required: true,
                                                message: "",
                                            }}
                                        obj={data}
                                        setObj={setData}
                                        detectChange={detectChange}
                                    />
                                </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>


                </Row>
            </Form>
            <Row className="btnpateintfooter" justify="end" gutter={20}>

                <Col span={3}>
                    <Button type="primary" onClick={() => showFamilyForm(false)}>Cancel</Button>
                </Col>
            </Row>
        </Card>
    )
}



import { useState, useEffect } from "react";
import { Row, Col, Form, Spin, message, Modal } from 'antd';
import { Card } from 'antd';
import Button from "../../../stateless/common/button";
import AuthLayout from "../../../stateless/layouts/authLayout";
import "./accountSettingsModal.less"
import InputPassword from "../../../stateless/common/inputPassword";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword, resetSelfPassword } from "../../../../../redux/actions/auth/authAction";
import { useHistory } from "react-router-dom";
import { AuthStateSelector, clearState, clearPassword } from "../../../../../redux/reducers/authReducer/authReducer";
import { AppRoutes } from "../../../../router/appRoutes";
import { Messages } from "../../../../constants/messages";
import { CommonIcons } from "../../../../constants/enums";


export const AccountSettingsModal = (props: any) => {

    const { isResetModalVisible, onReset, value, cancelCallback } = props

    const onCancelCallback = () => {
        if (cancelCallback) {
            cancelCallback();
        }
    }
    const dispatch = useDispatch()
    const { passwordGenerated } = useSelector(AuthStateSelector)
    const [entries, setEntries] = useState({
        newPasssword: "",
        confirmPassword: ""
    });
    const [error, setError] = useState<any>("")
    useEffect(() => {
        entries.newPasssword != entries.confirmPassword ? setError(Messages.PWD_DOESNOT_MATCH) : setError(null)
    }, [entries.confirmPassword])
    const history = useHistory();
    const handleChange = (event: any) => {
        setEntries({
            ...entries,
            [event.target.name]: event.target.value,
        })
    }
    const handleFormSubmit = () => {
        dispatch(resetSelfPassword({password: entries.newPasssword}))
    }
    useEffect(() => {
        dispatch(clearState())
        return () => {
            dispatch(clearState())
        }
    }, [])
    useEffect(() => {
        if (passwordGenerated.isSuccess == true) {
            message.success({
                content: Messages.PWD_CHANGED,
                duration: 5,
                key: "appNotification"
            })
            dispatch(clearPassword())
            cancelCallback()
        }
    }, [passwordGenerated.isSuccess, passwordGenerated.isError])

    return (
        <Modal
            key={isResetModalVisible}
            wrapClassName="resetModal"
            centered
            footer={null}
            onCancel={onCancelCallback}
            visible={isResetModalVisible}
        >
        <Spin spinning={passwordGenerated?.loading}>
            <Row>
                <Col span={24}>
                    <p className="create f-20"><b>Change Password</b></p>
                </Col>

            </Row>
            <Row className="accountSettingModal">

                <Form onFinish={handleFormSubmit} layout="vertical">
                    <Row className="newPass">
                        <Col span={24} >
                            <Row>
                                <Col span={24}>
                                    <InputPassword
                                        labelSubName="New Password"
                                        placeholder="password"
                                        name='newPasssword'
                                        rules={[
                                            { required: true, message: "Please enter the password" },
                                            {
                                                pattern: new RegExp(/^(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&,.#><|~{}():;"'/_=+-])[A-Za-z\d@$!%*?&,.#><|~{}():;"'/_=+-]{8,}$/),
                                                message: "Password must be a combination of alphabets, numbers, special characters and minimum 8 chracters"
                                            }
                                        ]}
                                        value={entries.newPasssword}
                                        onChange={handleChange}>
                                    </InputPassword>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="pass2">
                        <Col span={24}>
                            <Row>
                                <Col span={24}>
                                    <InputPassword
                                        labelSubName="Confirm New Password"
                                        placeholder="password"
                                        name='confirmPassword'
                                        rules={[
                                            entries?.confirmPassword?.length ?
                                                {
                                                    message: Messages.PWD_DOESNOT_MATCH,
                                                    validator: (_: any, value: any) => {
                                                        if (entries.newPasssword == value) {
                                                            return Promise.resolve();
                                                        } else {
                                                            return Promise.reject(Messages.PWD_DOESNOT_MATCH);
                                                        }
                                                    }
                                                } : {
                                                    required: true,
                                                    message: "Please enter the password"
                                                }
                                        ]}
                                        value={entries.confirmPassword}
                                        onChange={handleChange}>
                                    </InputPassword>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {
                        passwordGenerated.isError ?
                            <Row>
                                <Col span={24}>
                                    <p className="errorMessage f-14">{passwordGenerated?.errorStack}</p>
                                </Col>
                            </Row>
                            :
                            null
                    }
                    <Row gutter={10}>
                        <Col span={16}>
                            <Button type="primary" htmlType="submit"><span className="material-icons-outlined">{CommonIcons.lock}</span>Save Password</Button>
                        </Col>
                        <Col span={8}>
                            <Button type="primary" onClick={onCancelCallback}>CANCEL</Button>
                        </Col>
                    </Row>
                </Form>

            </Row>
        </Spin>
        </Modal>
    )
}


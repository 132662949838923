import {Row,Col,Card, Table, Divider} from "antd"
import { useEffect, useState } from "react"
import { chartIcon, expand, leftFilled, rightFilled, tableIcon, weightReadingsIcon } from "../../../../../images"
import { ExpandedModal } from "../expandedModal"
import { WeightChart } from "./wieghtChart"
import { WeightTable } from "./weightTable"
import { enumerateDaysBetweenDates, getBoundsForSingleReading, getFullChartDataWithEmptyData, getThresholdColumns, getThresholdsAccToUser } from "../../../../../../utility/utils"
import moment from "moment"
import { PatientStateSelector } from "../../../../../../redux/reducers/patient/patientReducer"
import { useDispatch,useSelector } from "react-redux"
import { AuthStateSelector } from "../../../../../../redux/reducers/authReducer/authReducer"
import { Biometricname, UserRoles } from "../../../../../constants/enums"
import CustomTooltip from "../../../common/toolTip"
import { getActiveBoundforWeight, getAssigneeId } from "../../../../../../utility/hrmServiceUtils"

export const Weight = (props: any) => {
    const { data,thresholds, params, setParams,deviceAssigned} = props
    const [expanded, setExpanded] = useState(false)
    const [viewType, setViewType] = useState(true)
    const [expandedViewType, setexpandedViewType] = useState(true);
    const [count ,setCount] = useState(6);
    const [daysView ,setDaysView] = useState(14)
const [alternateView , setAlternateView]= useState(30)
  const [chartData, setChartData] = useState<any[]>([]);
  const [tableData, setTableData] = useState<any[]>([])
  const [selectedScale, setSelectedScale] = useState("lbs")
const [showLabel, setShowLabel] = useState(true)
const [latestReading, setLatestReading] = useState<any>(null)

useEffect(()=>{
setTableData(data?.weight?.map((r: any) => {
  return {
    ...r,
    weightChange: r[getActiveBoundforWeight(r)]
  };
}))
},[data,params])

useEffect(()=>{
if(tableData?.length){
  setLatestReading(tableData[tableData?.length- 1])
}
},[tableData])

    const wtWrost = data?.weightWorst || []

  useEffect(()=>{
    if (selectedScale == "kgs") {
      setTableData(
        data?.weight?.map((r: any) => {
          return {
            ...r,
            weight: {
              ...r.weight,
              value:parseFloat((0.45359237 * r?.weight?.value).toFixed(2)),
            },
            weightChange: parseFloat((0.45359237 *r[getActiveBoundforWeight(r)]).toFixed(2))
          };
        })
      );
      setChartData(
        wtWrost?.map((d: any) => {
          var x:any = 0.45359237 *d ?.weightChange
          return {
            day: d?.day,
            weight: d?.weight,
            weightChange: isNaN(parseFloat(x.toFixed(2))) ? 0 : parseFloat(x.toFixed(2)),
            heartFailure: d?.heartFailure,
            obesity: d?.obesity,
            weightBound : {
              maxBound: parseFloat((0.45359237 * d?.weightBound?.maxBound).toFixed(2)),
              minBound: parseFloat((0.45359237 * d?.weightBound?.minBound).toFixed(2)),
            }          
          };
        })
      );
    } else if (selectedScale == "lbs") {
      setTableData(data?.weight?.map((r: any) => {
        return {
          ...r,
          weightChange: r[getActiveBoundforWeight(r)]
        }
      }))
      setChartData(
        wtWrost?.map((d: any) => {
          return {
            day: d?.day,
            weight: d?.weight,
            weightChange: d?.weightChange,
            heartFailure: d?.heartFailure,
            obesity: d?.obesity,
            weightBound : d?.weightBound    
          };
        })
      )
    }
  }, [selectedScale]) 

  const getAvgWeight = () => {
   var x:any = 0
   tableData?.forEach((z:any)=>{
     x= x+z?.weight?.value
   })
    if(data?.weight?.length > 0){
      return x/(data?.weight?.length)
    }else{
      return 0
    }
 }        
useEffect(()=>{
  setSelectedScale("lbs")
},[params?.startDate, params?.endDate])
        

          const onZoomClick = ()=>{
            if ( daysView==14) {
              setDaysView(30)
              setAlternateView(14)
              setCount(13)
                }else if(daysView==30){
              setDaysView(14)
              setAlternateView(30)
              setCount(29)
              
              }
      }
          const onModalClose = () => {
            setCount(6);
            setExpanded(false);
          };
          useEffect(() => {
            setChartData(wtWrost);
            if(wtWrost?.some((d:any)=>d?.weight||d?.weightChange)){
              setShowLabel(false)
            }else{
              setShowLabel(true)
            }
          }, [wtWrost]);
          useEffect(() => {
            // return()
              if(params?.startDate !== moment().subtract(count, "days")){
                setParams({
              ...params,
              startDate: moment().subtract(count, "days"),
              endDate: moment(),
            })
          }
          }, [count]);
        
const onOpenExpand = () => {
  setCount(29)
  setExpanded(true)
  }
          const onNextClick = (e: any) => { 
            setParams({...params, startDate: params?.startDate.add(6, "day"), endDate: params?.endDate.add(6, "day")})
          };
          const onPrevClick = (e: any) => {
          setParams({...params, startDate: params?.startDate.subtract(6, "day"), endDate: params?.endDate.subtract(6, "day")})
          
          };
    return (
      <Card className="readings-card weight">
        
        <Row>
          <Col span={3}>
            <CustomTooltip placement="top" color="#FFFF" title="Weight">
              <img src={weightReadingsIcon} className="readingDataIcon" />
            </CustomTooltip>
          </Col>
          <Col span={5}  style={{display:'flex',alignItems:'center'}}>
            <div className="units">
              <span
                className={selectedScale == "kgs" ? "active" : ""}
                onClick={() => setSelectedScale("kgs")}
              >
                kg
              </span>
              |
              <span
                className={selectedScale == "lbs" ? "active" : ""}
                onClick={() => setSelectedScale("lbs")}
              >
                lbs
              </span>
            </div>
          </Col>
          <Col md={12} xl={12} xxl={10} lg={12}>
            <span className="readings-additional-text  txtAvg f-10">
              7D Avg: <b>{getAvgWeight().toFixed(2)} {selectedScale}</b>
            </span>
          </Col>
          <Col
            lg={2} xl={2} xxl={3} md={2}
            onClick={() => setViewType(!viewType)}
            className="icons"
          >
            {viewType ? (
              <img src={tableIcon} className="utilIcon" />
            ) : (
              <img src={chartIcon} className="utilIcon" />
            )}
          </Col>
          <Col lg={2} xl={2} xxl={3} md={2} onClick={onOpenExpand} className="icons">
            <img src={expand} className="utilIcon icnExpnd" />
          </Col>
        </Row>
        <Row className="readings-table-row">
          <Col span={24}>
            {!expanded ? (
              <>
                {viewType ? (
                  <WeightChart chartData={chartData
                  //   getFullChartDataWithEmptyData(chartData, enumerateDaysBetweenDates(
                  //   params?.startDate,
                  //    params?.endDate
                  //  ))
                  } showLabel={showLabel} deviceAssigned={deviceAssigned}/>
                ) : (
                  <WeightTable data={tableData} thresholds={thresholds} getActiveBound={getActiveBoundforWeight}/>
                )}
              </>
            ) : null}
          </Col>
        </Row>
        <ExpandedModal
          expanded={expanded}
          setExpanded={setExpanded}
          onModalClose={onModalClose}
        >
          <Row align="middle" justify="end">
            <Col md={1} xl={1} lg={1}>
              <img src={weightReadingsIcon} />
            </Col>
            <Col  md={8} xl={7} lg={7}>
              <div className="expandedTitle">
                <span className="readingTitle f-14">Weight</span>
                {expandedViewType && viewType ? (
                  <>
                    <span className="daysViewCount">({alternateView} days view)</span>

                    <span onClick={onZoomClick} className="zoomLabel">
                      {" "}
                      <u>
                        <b>{daysView} days </b>
                      </u>
                    </span>
                  </>
                ) : null}
              </div>
            </Col>
            <Col md={6} lg={4} xl={4} >
              <span className="readings-additional-text expandedView f-12">
               {expanded? "30D Avg": "7D Avg"}: <b>{getAvgWeight().toFixed(2)} {selectedScale}</b>{" "}
              </span>
            </Col>
            <Col md={9} lg={7} xl={7} xxl={8} className="readings-additional-text expandedView f-12">
            <span className="latestReading">Latest Reading </span>
            {
              latestReading
              ?
              <>
            <span className="latestReading">
               ({moment.utc(latestReading?.date).local().format("MMM DD, YYYY")})
              </span>
              : 
              <b> {latestReading?.weight?.value} lbs</b>
              </>
              :
             <span className="latestReading">: <b>Not Available</b></span> 
            }
            </Col>
            <Col
             lg={1} md={2} xl={1} xxl={1}
              onClick={() => {
                setexpandedViewType(true);
                setViewType(!viewType);
              }}
              className="icons"
            >
              {expandedViewType && viewType ? (
                <img src={tableIcon} className="utilIcon" />
              ) : (
                <img src={chartIcon} className="utilIcon" />
              )}
            </Col>
            <Col md={6} lg={4} xl={4} xxl={3}>
      <div className="duration-filter durationFiltermap durationFilterModal f-14">
        <img src={leftFilled} onClick={onPrevClick} className="navigationIcon"/>
        <span>7 days</span>
        <img src={rightFilled} onClick={onNextClick} className="navigationIcon"/>
      </div>
    </Col>
          </Row>
          <Row className="readings-table-row" gutter={24}>
            
            {expandedViewType && viewType ? (
              <Col span={24}>
                <WeightChart chartData={chartData
                //   getFullChartDataWithEmptyData(chartData, enumerateDaysBetweenDates(
                //   params?.startDate,
                //    params?.endDate
                //  ))
              } expanded showLabel={showLabel} deviceAssigned={deviceAssigned}/>{" "}
              </Col>
            ) : (
              <>
                <Col  md={24} xl={17} lg={16} xxl={17} className="pTopTable">
                  <WeightTable data={tableData} thresholds={thresholds} getActiveBound={getActiveBoundforWeight}/>
                </Col>
                <Col md={24} xl={7} lg={8} xxl={7} className="expandedViewThresholds">
                  <Row>
                    <Col span={24} className="thresholdHeading thresholdHeading1">
                      Thresholds
                    </Col>
                    <Divider style={{ margin: "16px" }} />
                    <Col span={24}>
                      <Table
                        className="readingsTable tblThreshold"
                        columns={getThresholdColumns(Biometricname.WEIGHT, thresholds)}
                        dataSource={thresholds?.patientThresholdBounds}
                        pagination={false}
                      />
                    </Col>
                  </Row>
                </Col>
              </>
            )}
      
          </Row>
        </ExpandedModal>
      </Card>
    );
}
import { Cascader, Col, DatePicker, Form, Row, TimePicker } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updatePatientSchedule } from "../../../../../../redux/actions/hrm/hrmActions";
import { compareFormValues, removeEmptyKeys } from "../../../../../../utility/utils";
import {
  biometricTypeOptions,
  instructionOptions,
  recurrenceOptions,
} from "../../../../../constants/constants";
import { CommonIcons } from "../../../../../constants/enums";
import { Messages } from "../../../../../constants/messages";
import SelectInput from "../../../common/selectInput";
import CustomTooltip from "../../../common/toolTip";

export const EditScheduleForm = (props: any) => {
  const {schedule, scheduleData, setScheduleData, scheduleList, setConflicted,setShowConflict, getCountOption} = props;
  const dispatch = useDispatch() 
  const [multiple, setMultiple] = useState(false);
  const [edited, setEdited] = useState(false)
  const [disabledEndHours, setDisabledEndHours] = useState<any[]>([])
  const [disabledStartHours, setDisabledStartHours] = useState<any[]>([])
  const weekDays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
  useEffect(()=>{
    if(schedule){
    setScheduleData(schedule)
    }
  },[schedule])
const [startDefaultDateFormat, setStartDateFormat] = useState('DD MMM YYYY')
const [endDeafultDateFormat, setEndDateFormat] = useState('DD MMM YYYY')
const inputDateFormat = "MM-DD-YYYY"
  const isConflicted = async () => {
    var x: boolean = true
    var getCommonBiometricSch = scheduleList?.filter((sch:any)=>(sch?.biometricName === scheduleData?.biometricName) && (sch?.id !== scheduleData?.id))
    if(getCommonBiometricSch?.length){
    for await (const oldSch of getCommonBiometricSch) {
      // console.log(oldSch, 'oldSchedule')
      var olSchStartTime =  moment.utc(oldSch?.startTime, "HH:mm").local().format('HH:mm')
      var oldSchEndTime =  moment.utc(oldSch?.endTime, "HH:mm").local().format('HH:mm')
      var newSchStartUTC = moment(scheduleData?.fromDate+" "+olSchStartTime,`${startDefaultDateFormat} HH:mm`).utc().format(inputDateFormat)
      var newSchEndUTC = moment(scheduleData?.fromDate+" "+oldSchEndTime,`${startDefaultDateFormat} HH:mm`).utc().format(inputDateFormat)
      if(
        moment(scheduleData?.fromDate+" "+scheduleData?.startTime, `${startDefaultDateFormat} HH:mm`).isAfter(moment.utc(oldSch?.toDate+" "+oldSch?.endTime, 'YYYY-MM-DD HH:mm').local()) 
        ||
        moment(scheduleData?.toDate+" "+scheduleData?.endTime, `${endDeafultDateFormat} HH:mm`).isBefore(moment.utc(oldSch?.fromDate+" "+oldSch?.startTime,'YYYY-MM-DD HH:mm').local())
        ){
          // console.log('Continue for old schedulle end date',moment.utc(oldSch?.toDate+" "+oldSch?.endTime, 'YYYY-MM-DD HH:mm').local())
          //   console.log('Continue for old schedulle start date',moment.utc(oldSch?.fromDate+" "+oldSch?.startTime,'YYYY-MM-DD HH:mm').local())
          //   console.log('Continue for new schedulle start date',moment(scheduleData?.fromDate+" "+oldSch?.endTime, `${startDefaultDateFormat} HH:mm`))
          //   console.log('Continue for new schedulle end date',moment(scheduleData?.toDate+" "+oldSch?.startTime, `${endDeafultDateFormat} HH:mm`))
          x = false
          continue
        }
        else{
          // console.log('Continue for old schedulle start time',moment.utc(newSchStartUTC+" "+oldSch?.startTime, `${inputDateFormat} HH:mm:ss`).local() )
          //   console.log('Continue for old schedulle end time',moment.utc(newSchEndUTC+" "+oldSch?.endTime, `${inputDateFormat} HH:mm:ss`).local() )
          //   console.log('Continue for new schedulle end time',moment(scheduleData?.fromDate+" "+scheduleData?.endTime, `${startDefaultDateFormat} HH:mm`))
          //   console.log('Continue for new schedulle start time',moment(scheduleData?.fromDate+" "+scheduleData?.startTime, `${startDefaultDateFormat} HH:mm`))
          if((
            moment(scheduleData?.fromDate+" "+scheduleData?.startTime, `${startDefaultDateFormat} HH:mm`).isBefore(moment.utc(newSchStartUTC+" "+oldSch?.startTime, `${inputDateFormat} HH:mm:ss`).local())
              &&
            moment(scheduleData?.fromDate+" "+scheduleData?.endTime, `${startDefaultDateFormat} HH:mm`).isSameOrBefore(moment.utc(newSchStartUTC+" "+oldSch?.startTime, `${inputDateFormat} HH:mm:ss`).local())
            )
            ||
          (
            moment(scheduleData?.fromDate+" "+scheduleData?.startTime, `${startDefaultDateFormat} HH:mm`).isAfter(moment.utc(newSchStartUTC+" "+oldSch?.startTime, `${inputDateFormat} HH:mm:ss`).local())
            &&
            moment(scheduleData?.fromDate+" "+scheduleData?.startTime, `${startDefaultDateFormat} HH:mm`).isSameOrAfter(moment.utc(newSchEndUTC+" "+oldSch?.endTime, `${inputDateFormat} HH:mm:ss`).local()
          ))
          ){
            x = false
            continue
          } else{
            setConflicted(oldSch)
            x = true
            break;
          }
        }
    }
  }else{
    x = false
  }
    return x
  }

  
  const onEndDatePick = (date: any, dateString: any) => {
    setScheduleData({
      ...scheduleData,
      toDate: moment(dateString, inputDateFormat).format("YYYY-MM-DD"),
    });
setEndDateFormat("YYYY-MM-DD")    
  };


  const onStartDatePick = (date: any, dateString: any) => {
    setScheduleData({
      ...scheduleData,
      fromDate: moment(dateString, inputDateFormat).format("YYYY-MM-DD"),
    });
    setStartDateFormat("YYYY-MM-DD")    
  };

  const handleEndTime = (value: any, time: any) => {
    setScheduleData({
      ...scheduleData,
      endTime: moment(time,"HH:mm").format("HH:mm:ss"),
    }); 
    
  };


  const handleStartTime = (value: any, time: any) => {
    setScheduleData({
      ...scheduleData,
      startTime: moment(time, "HH:mm").format("HH:mm:ss"),
    });
    
  };

  const handleBiometricSelect = (value: any) => {
    setScheduleData({
      ...scheduleData,
      biometricName: value,
    });
    
  };
  const handleReccurrenceSelect = (value: any[]) => {
    if (
      (value && value[0] && value[0] == "daily") ||
      value?.some((x: any[]) => x?.indexOf("daily") > -1)
    ) {
      setMultiple(false);
      setScheduleData({
        ...scheduleData,
        recurrence: ["daily"],
      });
    } else {
          setMultiple(true);
          if(value[0] == "weekly"){
            setScheduleData({...scheduleData, recurrence: weekDays})
          }else{
          setScheduleData({
            ...scheduleData,
            recurrence: value?.map((s: any) => {
              if (s) return s[1];
            }),
          });
    }
  };
  
  }

  useEffect(()=>{
    var endhours = [];
    var startHours = []
    for(var i =0; i < moment(scheduleData?.startTime,'HH:mm').hour(); i++){
      endhours.push(i);
    }
    for(var j =24; j> moment(scheduleData?.endTime,'HH:mm').hour();j--){
      startHours.push(j)
    }
    setDisabledEndHours(endhours)
    setDisabledStartHours(startHours)

  },[scheduleData?.startTime, scheduleData?.endTime])

  const handleInstruction = (value: any) => {
    setScheduleData({
      ...scheduleData,
      instruction: value,
    });
    
  };
  const handleSkipCount =(value:any) =>{
    setScheduleData({
      ...scheduleData,
      skipcount:value,
    })
  }
  const getRecurrenceValue = () => {
    if (!multiple) {
      return [["Daily"]];
    }
  };
  const handleEditSubmit = async () => {
    var getConflict = await isConflicted()
    if(getConflict){
      setShowConflict(true)
    }else{
      dispatch(
        updatePatientSchedule({
          ...removeEmptyKeys(scheduleData),
          id: schedule?.id,
          fromDate: moment(scheduleData?.fromDate).format('YYYY-MM-DD'),
          toDate: moment(scheduleData?.toDate).format('YYYY-MM-DD'),
          startTime: moment(scheduleData?.fromDate+" "+scheduleData?.startTime).format('HH:mm:ss'),
          endTime: moment(scheduleData?.fromDate+" "+scheduleData?.endTime).format('HH:mm:ss'),
          skipCheck: true,
        })
      );
    }
  };
  return (

    <Form layout="vertical" onFinish={handleEditSubmit} key={scheduleData?.id} id="scheduleUpdate">
      <Row gutter={20} style={{ marginTop: "20px" }}>
        <Col span={4} md={6} lg={6} xl={6} xxl={3}>
          <SelectInput
            labelSubName="Select Biometric Type"
            placeholder="Select"
            disabled
            name="selectBiometricType"
            className="selectInput"
            bordered={true}
            rules={[
              {
                required: true,
                message: "Please select biometric type.",
              },
            ]}
            initialValue={scheduleData?.biometricName}
            optionValue={biometricTypeOptions}
            onChange={handleBiometricSelect}
          />
        </Col>
        <Col span={3} md={6} lg={6} xl={6} xxl={3}>
          <Row gutter={[0, 5]}>
            <Col span={24}>
              <Form.Item
                name="fromDate"
                label="Start Date"
                rules={[
                  {
                    required: true,
                    message: "Please select Start sate",
                  },
                ]}
                initialValue={moment(scheduleData?.fromDate)}
              >
                <DatePicker
                  className="dobPicker"
                  disabledDate={(date)=>date.isBefore(moment().subtract(1,"day"))}
                  onChange={onStartDatePick}
                  allowClear={false}
                  placeholder={inputDateFormat}
                  format={inputDateFormat}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={3} md={6} lg={6} xl={6} xxl={3}>
          <Row gutter={[0, 5]}>
            <Col span={24}>
              <Form.Item
                name="toDate"
                label="End Date"
                rules={[{ required: true, message: "Please select End Date" }]}
                initialValue={moment(scheduleData?.toDate)}
              >
                <DatePicker
                  className="dobPicker"
                  disabledDate={(date)=>date.isBefore(moment(scheduleData?.fromDate))}
                  onChange={onEndDatePick}
                  allowClear={false}
                  size="small"
                  placeholder={inputDateFormat}
                  format={inputDateFormat}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={3} md={6} lg={6} xl={6} xxl={3}>
          <Row gutter={[0, 5]}>
            <Col span={24}>
              <Form.Item
                name="startTime"
                label="Start Time"
                rules={[
                  {
                    required: true,
                    message: "Please select Start time",
                  },
                ]}
                initialValue={moment(scheduleData?.startTime , "HH:mm", "ha")}
              >
                <TimePicker
                  disabledHours={()=>disabledStartHours}
                  onChange={handleStartTime}
                  format={"HH:mm"}
                  use12Hours
                  size="large"
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={3} md={6} lg={6} xl={6} xxl={3}>
          <Row gutter={[0, 5]}>
            <Col span={24}>
              <Form.Item
                name="endTime"
                label="End Time"
                rules={[{ required: true, message: "Please select End time" }]}
                initialValue={moment(scheduleData?.endTime, "HH:mm", "ha")}
              >
                <TimePicker
                  disabledHours={()=>disabledEndHours}
                  onChange={handleEndTime}
                  format={"HH:mm"}
                  use12Hours
                  size="large"
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={3} md={6} lg={6} xl={6} xxl={3}>
          <Form.Item
            name="recurrence"
            label="Recurrence"
            className="txtRecurrence"
            rules={[{ required: true, message: "Please select recurrence" }]}
            initialValue={scheduleData?.recurrence}
          >
            <Cascader
              className="custom"
              style={{ width: "100%" }}
              options={recurrenceOptions}
              onChange={handleReccurrenceSelect}
              multiple={multiple}
              value={getRecurrenceValue()}
              changeOnSelect
              maxTagCount="responsive"
            />
          </Form.Item>
        </Col>
        <Col span={3} md={6} lg={6} xl={6} xxl={3}>
          <SelectInput
            labelSubName="Select Instructions"
            placeholder="Instructions"
            name="instructions"
            className="selectInput"
            bordered={true}
            initialValue={scheduleData?.instruction}
            optionValue={instructionOptions}
            onChange={handleInstruction}
          />
        </Col>
        <Col span={3} md={6} lg={6} xl={6} xxl={3} style={{marginTop:'-25px'}}>
        <CustomTooltip placement="top" heading="Note:" title={Messages.SKIP_COUNT_MESSAGE} color="#ffffff"  overlayClassName="infoToolTip"> 
                <div className="infoIconDiv"><span className="material-icons-outlined infoIcon">{CommonIcons.info}</span></div>
                </CustomTooltip>
           <SelectInput
            name="skipCount"
            labelSubName="Non-Adherance Count"
            placeholder="skip count"
            className="selectInput"
            bordered={true}
            initialValue={scheduleData?.skipcount}
            onChange={handleSkipCount}
            optionValue={getCountOption}
          />
        </Col>
      </Row>
    </Form>
  );
};
